import "overlayscrollbars/css/OverlayScrollbars.css";

import React, { useEffect } from "react";

import Home from "./Components/Home";
import { Router } from "react-router-dom";
import history from "./Services/history";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const App = ({ hideLoader }) => {
  const classes = useStyles();

  useEffect(() => {
    hideLoader();
  });

  return (
    <div className={classes.root}>
      <Router history={history}>
        <Home />
      </Router>
    </div>
  );
};

export default App;
