import {CircularProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import {HomeAppContext} from "./Home";
import Paper from "@mui/material/Paper";
import React from "react";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import {userStore} from "../Services/store";

export default function Logout() {
  const { homeDispatch } = React.useContext(HomeAppContext);
  const history = useHistory();

  React.useEffect(() => {
    userStore.clearProfile();
    homeDispatch({
      type: "CLEAR_USER_PROFILE",
      data: null,
    });
    history.push("/login");
  }, [history, homeDispatch]);

  return (
    <Paper>
      <Grid container alignItems={"center"} justifyContent={"center"} spacing={4}>
        <Grid item>
          <CircularProgress size={24} />
        </Grid>
        <Grid item>
          <Typography>Login out...</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
