import { Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import LinearProgressWithLabel from "../General/LinearProgressWithLabel";

export default function Progress({ notification }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications([...notifications, notification.message])
  }, [notification]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        {(notifications || []).map((notif, index) => (
          <React.Fragment key={index}>
            <Typography>
              {notif}
            </Typography>
          </React.Fragment>
        ))}
        {notification.showProgress &&
          <LinearProgressWithLabel
            value={notification.progress}
          />
        }
      </Grid>
    </Grid>
  );
}