import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Slide, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { forwardRef } from "react";
import api from "../../Services/api";
import { mappingsUploadEndpoint } from "../../Services/apiEndpoint";
import { HomeAppContext } from "../Home";
import Progress from "./Progress";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
  },
  floatRight: {
    float: "right"
  },
  detail: {
    marginLeft: "20px"
  }
}));

export default function PreflightSummary({ open, setOpen, summary, uploadParams,
  setResponse, openSummary, processNotification, setNotification
}) {
  const classes = useStyles();

  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [alertSeverity, setAlertSeverity] = useState(undefined);
  const [hasDownload, setHasDownload] = useState(false);
  const [addNonEquivalent, setAddNonEquivalent] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setAddNonEquivalent(true);
    }
  }, [open]);

  useEffect(() => {
    if (!summary) {
      return;
    }
    if (summary.found > 0 && summary.imported === 0) {
      setAlertSeverity('error');
    }
    else if (summary.found > 0 && summary.found === summary.imported) {
      setAlertSeverity('success');
    }
    else if (summary.found > 0 && summary.imported < summary.found) {
      setAlertSeverity('warning');
    }
    else {
      setAlertSeverity('error');
    }
    if (summary.hasError) {
      setAlertSeverity('error');
    }
    if (summary.downloadUrl) {
      setHasDownload(true);
    }
  }, [summary]);

  const getEntry = () => {
    return {
      ...uploadParams,
      tool: uploadParams.tool || null,
      xtrfClient: uploadParams.xtrfClient || null,
      tmsClient: uploadParams.tmsClient || null,
      salesforceClient: uploadParams.salesforceClient || null,
      files: (uploadParams.files || []).filter(x => { return !x.error }),
      addNonEquivalent: addNonEquivalent,
      taskId: summary.taskId
    };
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleDownload = () => {
    window.location = summary.downloadUrl;
  };
  const handleUpload = () => {
    setResponse(undefined);
    setNotification(undefined);
    setLoading(true);
    openSummary(true);

    homeState.connection.on(
      "ReceiveFileImportProgressMessage",
      (notification) => {
        setNotification(notification);
      }
    );

    const entryDto = getEntry();
    api.post(mappingsUploadEndpoint, entryDto, { timeout: 0 }).then((response) => {
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Languages Imported", type: "success" },
      });
      setLoading(false);
      setResponse(response.data);
      setOpen(false);
    })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {
          (processNotification && !summary) ? "Processing Validation" : "Validation Summary"
        }
      </DialogTitle>
      <DialogContent>
        {processNotification && !summary &&
          <Progress
            notification={processNotification}
          />
        }

        {summary &&
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Alert severity={alertSeverity}>{'Uploaded file(s) has been processed. Please find the summary below'}</Alert>
            </Grid>
            {summary.hasError && <Grid item sm={12}>
              <Alert severity={"error"}>{summary.error}</Alert>
            </Grid>}
            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item sm={2}>
                  <Box className={classes.header}>Records Found:</Box>
                  <Box className={classes.detail}>{summary.found}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>With Errors:</Box>
                  <Box className={classes.detail}>{summary.errors}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>Without Errors:</Box>
                  <Box className={classes.detail}>{summary.imported}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>Duplicates:</Box>
                  <Box className={classes.detail}>
                    {summary.duplicates}
                    {summary.duplicateInstances > 0 &&
                      <Typography variant='span'> ({summary.duplicateInstances})</Typography>
                    }
                  </Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>No Equivalencies:</Box>
                  <Box className={classes.detail}>{summary.noEquivalencies}</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              <Alert severity="info">Summary of the uploaded files below</Alert>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box className={classes.header}>File Name</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>Found</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>OK</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>With Errors</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>Duplicates</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>No Equivalencies</Box>
                </Grid>
              </Grid>
            </Grid>
            {(summary.summary || []).map((fileSummary, index) => (
              <Grid item sm={12} key={index}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Box>{fileSummary.fileName}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.languagesFound || '-'}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.languagesSaved || '-'}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.errorImports || '-'}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>
                      {fileSummary.duplicates || '-'}
                      {fileSummary.duplicateInstances > 0 &&
                        <Typography variant='span'> ({fileSummary.duplicateInstances})</Typography>
                      }
                    </Box>
                  </Grid>
                  <Grid item sm={2}>
                    <Box>{fileSummary.noEquivalencies || '-'}</Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item sm={12}>
              {hasDownload &&
                <Button
                  onClick={handleDownload}
                  color="primary"
                  className={classes.floatRight}
                >
                  Download Detail Report
                </Button>}
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            {summary.noEquivalencies > 0 &&
              <Grid item sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addNonEquivalent}
                      onChange={(event) => setAddNonEquivalent(event.target.checked)}
                    />
                  }
                  label={"Add non-equivalent languages to application"}
                />
              </Grid>
            }
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        {summary &&
          <React.Fragment>
            <Button
              onClick={handleClose}
              disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleUpload}
              disabled={loading}>
              Proceed
            </Button>
          </React.Fragment>
        }
      </DialogActions>
    </Dialog>
  );
}