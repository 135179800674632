import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import {Typography} from "@mui/material";

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          style={{ height: 15, borderRadius: 5 }}
        />
      </Box>
      <Box minWidth={35} style={{ textAlign: "right" }}>
        <Typography
          variant="body2"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
