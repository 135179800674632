import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  Grow,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import React, { forwardRef, useCallback, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import CustomAutocomplete from "../CustomAutocomplete";
import CustomDateRange from "../CustomDateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import { GeExportExcel } from "../GeneralIcons";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NumberFormat from "react-number-format";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import _ from "lodash";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    color: theme.palette.secondary.main,
  },
  selectIcon: {
    minWidth: 32,
  },
  multiSelect: {
    maxHeight: theme.spacing(4),
  },
  smallFont: {
    fontSize: 12,
  },
  multiSelectItem: {
    padding: 0,
  },
  multiSelectCheckbox: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  multiSelectSmall: {
    paddingBottom: 4,
  },
  multiSelectIcon: {
    marginRight: 5,
    "& svg": { verticalAlign: "middle!important", fontSize: "inherit" },
  },
  multiSelectText: {
    marginRight: 10,
  },
  multiSelectTypography: { display: "inline", paddingRight: 10, fontSize: 12 },
}));

const NumberFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={0}
      isNumericString
      style={{ textAlign: "right" }}
    />
  );
});

const RenderInput = ({
  localFilters,
  column,
  setDelayedValue,
  setImmediateValue,
  handleMouseDownClear,
  endAdornment = null,
  noLabel = false,
  autoFilter = true,
  size = "normal",
}) => {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = React.useState("");
  const [loading, setLoading] = useState(false);
  const filterName = column.filter.name ?? column.name;
  const type = ["text", "number"].includes(column.filter.type)
    ? column.filter.type
    : "text";
  const typeProps =
    type === "text"
      ? {}
      : {
          inputComponent: NumberFormatCustom,
        };
  const separators = column.filter.separators ?? [];

  const setValue = (event, columnName) => {
    const value = event.target.value;
    setCurrentValue(value);
    const separated =
      separators.length === 0
        ? value
        : separators.reduce((acc, x) => acc.replaceAll(x, "|"), value);
    const escapedValue = separated.replaceAll(",", "\\,");
    if (autoFilter) {
      setDelayedValue(escapedValue, filterName);
      setLoading(true);
    } else {
      setImmediateValue(escapedValue, filterName);
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [loading]);

  const handleClickClear = (key) => {
    setCurrentValue("");
    if (autoFilter) setImmediateValue("", filterName);
  };

  const handleKeyClear = (event, index) => {
    const key = event.key || event.keyCode;
    if (key === "Escape" || key === "Esc" || key === 27) {
      setCurrentValue("");
      if (autoFilter) setImmediateValue("", filterName);
    }
  };

  return (
    <FormControl fullWidth={column.filter.size ? false : true}>
      {!noLabel && (
        <InputLabel className={size === "small" ? classes.smallFont : null}>
          {column.description}
        </InputLabel>
      )}
      <Input
        fullWidth={column.filter.size ? false : true}
        style={column.filter.size ? { width: column.filter.size } : null}
        className={size === "small" ? classes.smallFont : null}
        value={currentValue}
        onChange={(event) => setValue(event, filterName)}
        placeholder={
          column.filter.placeholder ?? "Filter " + column.description
        }
        onKeyDown={(event) => handleKeyClear(event, filterName)}
        endAdornment={
          <InputAdornment position="end">
            {loading && <CircularProgress size={size === "small" ? 12 : 16} />}
            {localFilters[filterName] !== "" &&
            localFilters[filterName] !== undefined ? (
              <IconButton
                size={"small"}
                fontSize={"small"}
                aria-label="clear field"
                onClick={() => handleClickClear(filterName)}
                onMouseDown={handleMouseDownClear}
              >
                <ClearIcon fontSize={"inherit"} />
              </IconButton>
            ) : (
              <FilterListIcon fontSize={"inherit"} />
            )}
            {endAdornment !== null && endAdornment}
          </InputAdornment>
        }
        {...typeProps}
      />
    </FormControl>
  );
};

const RenderSelect = ({
  localFilters,
  column,
  setImmediateValue,
  size = "normal",
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState("");

  const filterName = column.filter.name ?? column.name;

  const handleChange = (value) => {
    setSelectedValue(value);
    setImmediateValue(value, filterName);
  };

  const setDefaultValue = useCallback(
    (value) => {
      setSelectedValue(value);
      setImmediateValue(value, filterName);
    },
    [filterName, setImmediateValue]
  );

  React.useEffect(() => {
    if (column.filter.defaultValue) setDefaultValue(column.filter.defaultValue);
  }, [column.filter.defaultValue, setDefaultValue]);

  return (
    <FormControl fullWidth>
      <InputLabel
        shrink={true}
        className={size === "small" ? classes.smallFont : null}
      >
        {column.description}
      </InputLabel>
      <Select
        fullWidth
        value={selectedValue}
        displayEmpty
        onChange={(event) => handleChange(event.target.value)}
        renderValue={(value) => {
          const item = column.filter.value.find((x) => x.id === value);
          return item ? (
            <React.Fragment>
              {item.icon && (
                <span className={classes.multiSelectIcon}>{item.icon}</span>
              )}
              <span>{item.text}</span>
            </React.Fragment>
          ) : (
            "All"
          );
        }}
      >
        {column.filter.allowAll !== false && (
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
        )}

        {column.filter.value.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.icon && (
              <ListItemIcon className={classes.selectIcon}>
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const RenderMultiSelect = ({
  localFilters,
  column,
  setImmediateValue,
  autoFilter = true,
  size = "normal",
}) => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = React.useState([]);
  const filterName = column.filter.name ?? column.name;

  const indexedItems = column.filter.value.reduce((acc, val) => {
    return { ...acc, [val.id]: val };
  }, {});

  const handleChange = (value) => {
    setSelectedValues(value);
    if (autoFilter) {
      const timer = setTimeout(() => {
        setImmediateValue(value.join("|"), filterName);
      }, 1500);

      return () => clearTimeout(timer);
    } else {
      setImmediateValue(value.join("|"), filterName);
    }
  };

  const setDefaultValue = useCallback(
    (value) => {
      setSelectedValues(value);
      setImmediateValue(value.join("|"), filterName);
    },
    [filterName, setImmediateValue]
  );

  React.useEffect(() => {
    if (column.filter.defaultValue) setDefaultValue(column.filter.defaultValue);
  }, [column.filter.defaultValue, setDefaultValue]);

  return (
    <FormControl fullWidth>
      <InputLabel
        shrink={true}
        className={size === "small" ? classes.smallFont : null}
      >
        {column.description}
      </InputLabel>
      <Select
        className={
          size === "small"
            ? clsx(classes.multiSelect, classes.smallFont)
            : classes.multiSelect
        }
        fullWidth
        multiple
        displayEmpty
        value={selectedValues}
        onChange={(event) => handleChange(event.target.value)}
        renderValue={(selected) =>
          selected.length !== 0
            ? selected.map((x, index) => (
                <div key={index} className={classes.multiSelectTypography}>
                  {indexedItems[x].icon && (
                    <span className={classes.multiSelectIcon}>
                      {indexedItems[x].icon}
                    </span>
                  )}
                  {indexedItems[x].text}
                </div>
              ))
            : "All"
        }
      >
        {column.filter.value.map((item, index) => (
          <MenuItem
            key={index}
            value={item.id}
            className={classes.multiSelectItem}
          >
            <Checkbox
              checked={selectedValues.indexOf(item.id) > -1}
              className={classes.multiSelectCheckbox}
            />
            {item.icon && (
              <div className={classes.multiSelectIcon}>{item.icon}</div>
            )}
            <ListItemText
              className={classes.multiSelectText}
              primary={item.text}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const RenderAutocomplete = ({
  localFilters,
  column,
  setImmediateValue,
  autoFilter = true,
  size = "normal",
}) => {
  const [state, setState] = React.useState({
    initialized: false,
    selectedValues:
      column.filter.defaultValue ?? (column.filter.options.multiple ? [] : ""),
  });
  const [loading, setLoading] = useState(false);
  const filterName = column.filter.name ?? column.name;
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [loading]);

  const delayedSetFiltersValues = React.useMemo(
    () =>
      _.debounce((value, name, callback) => {
        setImmediateValue(value, name);
      }, 1000),
    [setImmediateValue]
  );

  React.useEffect(() => {
    if (
      column.filter.required &&
      (column.filter.defaultValue === undefined ||
        (column.filter.options.multiple
          ? column.filter.defaultValue.length === 0
          : column.filter.defaultValue === ""))
    ) {
      setImmediateValue("", filterName);
    }
  }, [
    column.filter.required,
    column.filter.defaultValue,
    column.filter.options.multiple,
    setImmediateValue,
    filterName,
  ]);

  const onAutocompleteChange = (values) => {
    const newValues = column.filter.options.multiple
      ? values.map((x) => x.id)
      : values === ""
      ? []
      : [values.id];

    const originalValues = column.filter.options.multiple
      ? state.selectedValues.map((x) => x.id)
      : state.selectedValues === ""
      ? []
      : [state.selectedValues.id];

    const sameValues =
      originalValues.length === newValues.length &&
      originalValues.some((x) => newValues.includes(x));

    const initialized = state.initialized;
    setState((prevState) => ({
      ...prevState,
      initialized: true,
      selectedValues: values,
    }));
    if (!sameValues || !initialized) {
      const preparedValue = column.filter.options.multiple
        ? values.map((x) => x.id).join("|")
        : values === ""
        ? values
        : values.id;
      if (autoFilter) {
        if (column.filter.options.multiple) {
          setLoading(true);
          delayedSetFiltersValues(preparedValue, filterName);
        } else {
          setImmediateValue(preparedValue, filterName);
        }
      } else {
        setImmediateValue(preparedValue, filterName);
      }
    }
  };

  return (
    <CustomAutocomplete
      value={state.selectedValues}
      setValue={onAutocompleteChange}
      related={
        column.filter.related
          ? localFilters[column.filter.related] &&
            localFilters[column.filter.related] !== ""
            ? localFilters[column.filter.related]
                .split("|")
                .map((x) => ({ id: x }))
            : ""
          : null
      }
      label={column.description}
      placeholder={
        column.filter.options.multiple
          ? `Select ${column.description}/s`
          : `Select ${column.description}`
      }
      noOptionsText={`No ${column.description} found`}
      loadingText={"Searching"}
      externalLoading={loading}
      margin={"none"}
      {...column.filter.options}
      size={size}
    />
  );
};

const RenderDateRange = ({
  localFilters,
  column,
  setImmediateValue,
  size = "normal",
}) => {
  const filterName = column.filter.name ?? column.name;
  const externalValues = column.filter.value
    ? { periodFilters: column.filter.value }
    : {};

  const onSetValue = (value) => {
    if (value !== null) {
      setImmediateValue(`${value.from}|${value.to}`, filterName);
    } else {
      setImmediateValue(null, filterName);
    }
  };

  return (
    <CustomDateRange
      defaultValue={column.filter.defaultValue ?? 0}
      {...externalValues}
      {...column.filter.options}
      onSetValue={onSetValue}
      label={column.description}
      size={size}
    />
  );
};

const StyledMenu = withStyles({
  paper: {},
})((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const SearchButton = ({
  loading,
  handleSearch,
  exportEnabled,
  handleExport,
  urlExport,
  canExport,
}) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRefMenu = React.useRef(null);

  const handleToggleMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRefMenu.current && anchorRefMenu.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleExportClick = (index) => {
    setOpenMenu(false);
    if (handleExport) handleExport(index);
  };

  return (
    <React.Fragment>
      {exportEnabled ? (
        <React.Fragment>
          <ButtonGroup
            variant="outlined"
            ref={anchorRefMenu}
            aria-label="split button"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSearch}
              disabled={loading}
              title="Search"
              style={{ minWidth: "40px", maxWidth: "40px" }}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              aria-controls={openMenu ? "split-button-menu" : undefined}
              aria-expanded={openMenu ? "true" : undefined}
              aria-label="select options"
              aria-haspopup="menu"
              onClick={handleToggleMenu}
              title="More options"
              disabled={loading}
              style={{ minWidth: "10px", maxWidth: "10px" }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={openMenu}
            anchorEl={anchorRefMenu.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList id="split-button-menu" color="secondary">
                      {urlExport.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={(event) => handleExportClick(index)}
                          disabled={!canExport}
                        >
                          <ListItemIcon>
                            <GeExportExcel />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {item.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      ) : (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleSearch}
          disabled={loading}
          title="Search"
          style={{ minWidth: "40px", maxWidth: "40px" }}
        >
          <SearchIcon />
        </Button>
      )}
    </React.Fragment>
  );
};

const SimpleMenuButton = ({
  loading,
  exportEnabled,
  handleExport,
  canExport,
  urlExport,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportClick = (index) => {
    setAnchorEl(null);
    if (handleExport) handleExport(index);
  };

  return (
    <React.Fragment>
      {exportEnabled ? (
        <React.Fragment>
          <IconButton size="small" onClick={handleClick} disabled={loading}>
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {urlExport.map((item, index) => (
              <MenuItem
                key={index}
                onClick={(event) => handleExportClick(index)}
                disabled={!canExport}
              >
                <ListItemIcon>
                  <GeExportExcel />
                </ListItemIcon>
                <Typography variant="inherit">{item.label}</Typography>
              </MenuItem>
            ))}
          </StyledMenu>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const Filters = ({
  columns,
  additionalFilters,
  filters,
  setFiltersValues,
  autoFilter = true,
  exportEnabled = false,
  urlExport,
  canExport = false,
  onExport = null,
  loading = false,
  size = "normal",
  exportLabel = "Export",
}) => {
  const [localFilters, setLocalFilters] = useState({ ...filters });
  const hasColumnFilters = columns.find(
    (column) => column.filter.enabled === true
  );

  const delayedSetFiltersValues = React.useMemo(
    () =>
      _.debounce((values, callback) => {
        setFiltersValues(values);
      }, 1000),
    [setFiltersValues]
  );

  const handleOnFilterClick = () => {
    setFiltersValues(localFilters);
  };

  const setDelayedValue = useCallback(
    (value, key) => {
      setLocalFilters({ ...localFilters, [key]: value });
      if (autoFilter) delayedSetFiltersValues({ [key]: value });
    },
    [autoFilter, delayedSetFiltersValues, localFilters]
  );

  const setImmediateValue = useCallback(
    (value, key) => {
      setLocalFilters((prevState) => ({ ...prevState, [key]: value }));
      if (autoFilter) setFiltersValues({ [key]: value });
    },
    [autoFilter, setFiltersValues]
  );

  const handleMouseDownClear = (event) => {
    event.preventDefault();
  };

  return (
    <div className="nonPrintArea">
      {additionalFilters.length !== 0 && (
        <Grid container alignItems="center">
          <Grid item style={{ flex: 1 }}>
            <Grid container spacing={1}>
              {additionalFilters.map((additionalFilter, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={additionalFilter.size}>
                    {
                      {
                        number: (
                          <RenderInput
                            localFilters={localFilters}
                            column={additionalFilter}
                            setDelayedValue={setDelayedValue}
                            setImmediateValue={setImmediateValue}
                            handleMouseDownClear={handleMouseDownClear}
                            autoFilter={autoFilter}
                            size={size}
                          />
                        ),
                        text: (
                          <RenderInput
                            localFilters={localFilters}
                            column={additionalFilter}
                            setDelayedValue={setDelayedValue}
                            setImmediateValue={setImmediateValue}
                            handleMouseDownClear={handleMouseDownClear}
                            autoFilter={autoFilter}
                            size={size}
                          />
                        ),
                        select: (
                          <RenderSelect
                            localFilters={localFilters}
                            column={additionalFilter}
                            setImmediateValue={setImmediateValue}
                            size={size}
                          />
                        ),
                        multiSelect: (
                          <RenderMultiSelect
                            localFilters={localFilters}
                            column={additionalFilter}
                            setImmediateValue={setImmediateValue}
                            autoFilter={autoFilter}
                            size={size}
                          />
                        ),
                        autocomplete: (
                          <RenderAutocomplete
                            localFilters={localFilters}
                            column={additionalFilter}
                            setImmediateValue={setImmediateValue}
                            autoFilter={autoFilter}
                            size={size}
                          />
                        ),
                        dateRange: (
                          <RenderDateRange
                            localFilters={localFilters}
                            column={additionalFilter}
                            setImmediateValue={setImmediateValue}
                            size={size}
                          />
                        ),
                      }[additionalFilter.filter.type]
                    }
                  </Grid>
                  {additionalFilter.filter.break && (
                    <Grid
                      item
                      style={{ flexBasis: "100%", height: 0, padding: 0 }}
                    ></Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          {!autoFilter && (
            <Grid item>
              <SearchButton
                loading={loading}
                handleSearch={handleOnFilterClick}
                exportEnabled={exportEnabled}
                handleExport={onExport}
                canExport={canExport}
                urlExport={urlExport}
              />
            </Grid>
          )}
          {autoFilter && exportEnabled && (
            <Grid item>
              <SimpleMenuButton
                loading={loading}
                exportEnabled={exportEnabled}
                handleExport={onExport}
                canExport={canExport}
                urlExport={urlExport}
              />
            </Grid>
          )}
        </Grid>
      )}
      {hasColumnFilters && (
        <Grid container spacing={2}>
          {columns.map((column, index) => (
            <Grid item xs={12} md={column.size} key={index}>
              {column.filter.enabled
                ? {
                    text: (
                      <RenderInput
                        localFilters={localFilters}
                        column={column}
                        setDelayedValue={setDelayedValue}
                        setImmediateValue={setImmediateValue}
                        autoFilter={autoFilter}
                        handleMouseDownClear={handleMouseDownClear}
                        size={size}
                      />
                    ),
                    select: (
                      <RenderSelect
                        localFilters={localFilters}
                        column={column}
                        setImmediateValue={setImmediateValue}
                        size={size}
                      />
                    ),
                    multiSelect: (
                      <RenderMultiSelect
                        localFilters={localFilters}
                        column={column}
                        setImmediateValue={setImmediateValue}
                        autoFilter={autoFilter}
                        size={size}
                      />
                    ),
                    autocomplete: (
                      <RenderAutocomplete
                        localFilters={localFilters}
                        column={column}
                        setImmediateValue={setImmediateValue}
                        autoFilter={autoFilter}
                        size={size}
                      />
                    ),
                    dateRange: (
                      <RenderDateRange
                        localFilters={localFilters}
                        column={column}
                        setImmediateValue={setImmediateValue}
                        size={size}
                      />
                    ),
                  }[column.filter.type]
                : ""}
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default Filters;
