import * as serviceWorker from "./serviceWorker";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

const loader = document.querySelector(".loader-container");
const hideLoader = () => loader.parentNode?.removeChild(loader); // loader.classList.add("loader--hide");

ReactDOM.render(
  <App hideLoader={hideLoader} />,
  document.getElementById("root")
);

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/*
const configuration = {
  onUpdate: (registration) => {
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: (registration) => {
    console.info("service worker on success state");
    console.log(registration);
  },
  onWaiting: (registration) => {
    console.info("service worker on waiting state");
    console.log(registration);
  },
};
serviceWorker.register(configuration);
*/
