import ErrorIcon from "@mui/icons-material/Error";
import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 200,
    opacity: "30%",
  },
  message: {
    color: theme.palette.secondary.main,
  },
}));

export default function ErrorFound({ message = "" }) {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      direction={"column"}
    >
      <Grid item>
        <ErrorIcon className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
    </Grid>
  );
}
