import { Alert, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

import React from "react";
import _ from "lodash";
import EquivalencyMapper from "./EquivalencyMapper";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { EquivalencyMappingEntry } from "./EquivalencyMappingEntry";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
  },
}));

const EquivalencyConfiguration = ({
  entry = null,
  onChange,
  fileParsed = null,
}) => {
  const classes = useStyles();

  const [caseSensitive, setCaseSensitive] = React.useState(false);
  const [hyphenIsUnderscore, setHyphenIsUnderscore] = React.useState(false);

  useEffect(() => {
    onChange(caseSensitive, 'caseSensitive');
    onChange(hyphenIsUnderscore, 'hyphenIsUnderscore');
  }, [caseSensitive, hyphenIsUnderscore]);

  const onAddMapping = (mappings) => {
    onChange(mappings, 'mappings');
  }

  const removeMapping = (index) => {
    const mappings = _.reject(entry.equivalencyConfiguration.mappings, (item, idx) => { return index === idx; });
    onChange(mappings, 'mappings');
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h6">Equivalency Configuration</Typography>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={caseSensitive}
                  onChange={(event) => setCaseSensitive(event.target.checked)}
                />
              }
              label={"Case sensitive"}
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hyphenIsUnderscore}
                  onChange={(event) => setHyphenIsUnderscore(event.target.checked)}
                />
              }
              label={"Make hyphen and underscore equivalent"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <EquivalencyMapper
          entry={entry}
          fileParsed={fileParsed}
          onAddMapping={onAddMapping}
        />
      </Grid>
      {entry.equivalencyConfiguration.mappings.length === 0 ? (
        <Grid item sm={12}>
          <Alert severity="info">"No Equivalency Configurations Added"</Alert>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Box component={"div"} className={classes.header}>{"Tool"}</Box>
              </Grid>
              <Grid item sm={3}>
                <Box component={"div"} className={classes.header}>{"Standard Column"}</Box>
              </Grid>
              <Grid item sm={4}>
                <Box component={"div"} className={classes.header}>{"Matching Column"}</Box>
              </Grid>
            </Grid>
          </Grid>
          {entry.equivalencyConfiguration.mappings.map((mapping, index) => (
            <EquivalencyMappingEntry
              key={index}
              index={index}
              mapping={mapping}
              handleRemove={removeMapping}
            />
          ))}
        </React.Fragment>
      )}
    </Grid>
  );
};

export default EquivalencyConfiguration;
