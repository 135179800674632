import { Box, CircularProgress, Collapse, IconButton, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import moment from "moment-timezone";
import { defaultDateTimeFormat } from "../../Services/constantsAndTools";
import CustomAvatar from "../General/CustomAvatar";
import { makeStyles } from "@mui/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import api from "../../Services/api";
import { auditLogEndpoint } from "../../Services/apiEndpoint";
import { useContext } from "react";
import { HomeAppContext } from "../Home";

const useStyles = makeStyles((theme) => ({
  logHeader: {
    fontWeight: 'bold',
  }
}));

const ChangeLog = ({
  changeLogs = []
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <Typography variant="span" className={classes.logHeader}>Property Name</Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography variant="span" className={classes.logHeader}>Old Value</Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography variant="span" className={classes.logHeader}>New Value</Typography>
      </Grid>
      {(changeLogs || []).map((item, index) => (
        <React.Fragment key={index}>
          <Grid item sm={4}>
            <Typography variant="span">{item.propertyName || "#N/A"}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="span">{item.oldValue || "#N/A"}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="span">{item.newValue || "#N/A"}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default ChangeLog;
