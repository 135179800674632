import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { isAuthorizedEndpoint, toolGroupsEndpoint } from "../../Services/apiEndpoint";
import { errorManagement } from "../../Services/errorManagement";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import MyTable from "../General/Pagination/MyTable";
import Entry from "./Entry";
import Create from "./Create";
import Edit from "./Edit";
import ConfirmDialog from "../General/ConfirmDialog";

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const [deleteParams, setDeleteParams] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const isAdmin = homeState.userProfile.role === "Admin";
  const tableOptions = {
    columns: [
      {
        name: "Name",
        description: "Name",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: isAdmin ? 10 : 12
      }
    ],
    additionalFilters: [
      {
        name: "Name",
        description: "Name",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Name",
        },
        size: 4,
      }
    ]
  };

  React.useEffect(() => {
    api
      .get(isAuthorizedEndpoint)
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Tool Groups",
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);


  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickConfirmDelete = (event, entry) => {
    setDeleteParams({ id: entry.id });
    setOpenDelete(true);
  };
  const acceptDelete = (params) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Deleting", loading: true },
    });
    api.delete(`${toolGroupsEndpoint}/${params.id}`).then((response) => {
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Tool Group Deleted", type: "success" },
      });
      refreshItems();
    }).catch((error) => {
      errorManagement.formErrors(error, homeDispatch);
    });
  };
  const cancelDelete = () => {
    setDeleteParams(null);
  };

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        {isAdmin && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New Group
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MyTable
            options={tableOptions}
            url={`${toolGroupsEndpoint}/list`}
            refresh={refresh}
            label={"Tool Groups"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                allowActions={isAdmin}
                onEdit={handleClickEdit}
                onDelete={handleClickConfirmDelete}
              />
            )}
          />
        </Grid>
      </Grid>
      {isAdmin && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
          <ConfirmDialog
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Delete Tool Group"}
            message={"Are you sure that you want to delete this Tool Group?"}
            onCloseSuccess={acceptDelete}
            successParams={deleteParams}
            onClose={cancelDelete}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
