import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./Form";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { languagesEndpoint } from "../../Services/apiEndpoint";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "85vh!important",
    maxHeight: "85vh!important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  tool: {},
  identifier: "",
  name: "",
  shortName: "",
  code: "",
  iso639Dash1: "",
  iso639Dash2: "",
  appliesTo: { id: 1 },
  externalId: '',
  xtrfClient: "",
  tmsClient: "",
  salesforceClient: "",
  equivalencies: [],
  writingDirection: { id: 1 },
  writingSystem: { id: 1 },
};

export default function Create({ open, setOpen, onCreate }) {
  const classes = useStyles();

  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(initialEntry);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  React.useEffect(() => {
    setSubmitted(false);
    setErrors([]);
    setInternalEntry(initialEntry);
    setLoading(false);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Creating Mapping", loading: true },
    });
    setSubmitted(true);
    setLoading(true);
    const entryDto = {
      ...internalEntry,
      tool: internalEntry.tool || null,
      externalId: internalEntry.externalId || null,
      xtrfClient: internalEntry.xtrfClient || null,
      tmsClient: internalEntry.tmsClient || null,
      salesforceClient: internalEntry.salesforceClient || null,
      equivalencies: (internalEntry.equivalencies || []).map(x => x.id)
    };

    api.post(languagesEndpoint, entryDto)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Mapping created", type: "success" },
        });
        setOpen(false);
        setLoading(false);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">{"New Mapping"}</DialogTitle>
      <DialogContent>
        <Form
          entry={internalEntry}
          errors={errors}
          setEntry={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          autoFocus
          disabled={loading || saveDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
