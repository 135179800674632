import { standardColumnsEndpoint, toolsDropdownEndpoint } from "../../../Services/apiEndpoint";
import CustomAutocomplete from "../../General/CustomAutocomplete";
import _ from "lodash";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";

const initialEntry = {
  tools: [],
  standardColumn: undefined,
  matchingColumn: undefined
};

const EquivalencyMapper = ({
  entry,
  fileParsed = null,
  loading,
  onAddMapping
}) => {

  const [configEntry, setConfigEntry] = useState(initialEntry);
  const [validated, setValidated] = useState(false);
  const [disabledTools, setDisabledTools] = useState([]);

  useEffect(() => {
    const mappers = (entry.equivalencyConfiguration.mappings || []).map(x => x.tool);
    const tool = entry.tool?.id ? [entry.tool] : [];
    setDisabledTools([...mappers, ...tool]);
  }, [entry]);

  useEffect(() => {
    if (!fileParsed) {
      setConfigEntry((prevEntry) => ({ ...prevEntry, matchingColumn: undefined }));
    }
  }, [fileParsed]);

  useEffect(() => {
    setValidated(_.isObject(configEntry.tools) && _.isObject(configEntry.standardColumn) && _.isObject(configEntry.matchingColumn));
  }, [configEntry, setValidated]);

  const onValueChange = (value, property) => {
    setConfigEntry({ ...configEntry, [property]: value });
  };

  const addMapping = () => {
    const tools = configEntry.tools;
    const mappings = tools.map((tool) => {
      return {
        tool: tool,
        standardColumn: configEntry.standardColumn,
        matchingColumn: configEntry.matchingColumn
      };
    });
    onAddMapping([...entry.equivalencyConfiguration.mappings, ...mappings]);
    setConfigEntry(initialEntry);
    setDisabledTools([...disabledTools, ...tools]);
  }

  const columnOptions = (fileParsed?.originalFileColumnNames || []).map((x) => { return { id: x, text: x } });

  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <CustomAutocomplete
          value={configEntry.tools}
          setValue={(value) => onValueChange(value, "tools")}
          related={null}
          url={toolsDropdownEndpoint}
          liveOptions={false}
          label={"Tool"}
          placeholder={"Select Tool(s)"}
          noOptionsText={"No tool found"}
          loadingText={"Searching"}
          required={true}
          multiple={true}
          optionsDisabled={disabledTools}
        />
      </Grid>
      <Grid item sm={3}>
        <CustomAutocomplete
          value={configEntry.standardColumn}
          liveOptions={false}
          setValue={(value) => onValueChange(value, "standardColumn")}
          url={standardColumnsEndpoint}
          related={null}
          label={"Standard Column"}
          placeholder={"Standard Column"}
          noOptionsText={"No Standard column found"}
          loadingText={"Searching"}
          required={true}
        />
      </Grid>
      <Grid item sm={4}>
        <CustomAutocomplete
          value={configEntry.matchingColumn}
          liveOptions={false}
          setValue={(value) => onValueChange(value, "matchingColumn")}
          related={null}
          externalOptions={columnOptions}
          label={"Matching Column"}
          placeholder={"Matching Column"}
          noOptionsText={"No Matching column found"}
          loadingText={"Searching"}
          disabled={loading || fileParsed === null}
          required={true}
        />
      </Grid>
      <Grid item sm={1}>
        <Button
          onClick={addMapping}
          color="primary"
          autoFocus
          disabled={!validated}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

export default EquivalencyMapper;