import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const useStyles = makeStyles((theme) => ({
  spacer: { height: 25 },
  trackHorizontal: {
    position: "absolute",
    minHeight: 12,
    right: 2,
    top: 2,
    left: 2,
    borderRadius: 3,
  },

  thumbHorizontal: {
    position: "relative",
    display: "block",
    minHeight: 12,
    cursor: "pointer",
    borderRadius: "inherit",
    backgroundColor: theme.palette.mode === "dark" ? "grey" : "lightgrey",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
}));

const renderCell = (value) => {
  if (value === undefined || value === null || value === "") return value;
  if (typeof value !== "string") return value;
  if (value.length < 100) return value;
  return (
    <div>
      <span>{value.substr(0, 100)}</span>
      <Tooltip title={value}>
        <span>...</span>
      </Tooltip>
    </div>
  );
};

const PreviewParser = ({ file = null, setEntry, loading, setSaveDisabled }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h6">Original File preview from {file?.fileName}</Typography>
      </Grid>
      {file === null ? (
        <Grid item sm={12}>
          <Alert severity="info">"No Files Preview"</Alert>
        </Grid>
      ) : (
        <Grid item sm={12}>
          <Scrollbars
            autoHeight
            autoHeightMin="100%"
            autoHeightMax="100%"
            renderTrackHorizontal={(props) => (
              <div {...props} className={classes.trackHorizontal} />
            )}
            renderThumbHorizontal={(props) => (
              <div {...props} className={classes.thumbHorizontal} />
            )}
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className={classes.spacer}></div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {file.originalFileColumnNames.map((x, index) => (
                    <TableCell key={index}>{x}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {file.originalFilePreview.map((row, index) => (
                  <TableRow key={index}>
                    {file.originalFileColumnNames.map((col, index) => (
                      <TableCell key={index}>
                        {renderCell(
                          row.columns.find((x) => x.key === col)?.value
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbars>
        </Grid>
      )}
    </Grid>
  );
};

export default PreviewParser;
