import { TextField } from "@mui/material";

import Grid from "@mui/material/Grid";
import React from "react";
import _ from "lodash";
import makeStyles from '@mui/styles/makeStyles';
import CustomAutocomplete from "../General/CustomAutocomplete";
import { toolGroupsDropdownEndpoint } from "../../Services/apiEndpoint";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dropdowns: {
    marginTop: "16px"
  }
}));

export default function Form({
  entry = null,
  errors = [],
  setEntry,
  loading,
  setSaveDisabled,
  onSetDirty = null,
}) {
  const classes = useStyles();

  const onValueChange = (value, property) => {
    setEntry({ ...entry, [property]: value });
    if (onSetDirty !== null) onSetDirty(true);
  };
  const onInputChange = (event, property) => {
    setEntry({ ...entry, [property]: event.target.value });
    if (onSetDirty !== null) onSetDirty(true);
  };

  return entry === null ? null : (
    <React.Fragment>
      <Grid container className={classes.paper} spacing={2}>
        <Grid item sm={3} className={classes.dropdowns}>
          <CustomAutocomplete
            value={entry.group}
            setValue={(value) => onValueChange(value, "group")}
            related={null}
            url={toolGroupsDropdownEndpoint}
            liveOptions={false}
            label={"Tool Group"}
            placeholder={"Select a tool group"}
            noOptionsText={"No tool group found"}
            loadingText={"Searching"}
            error={_.isArray(errors.Group)}
            helperText={_.isArray(errors.Group) && errors.Group.join()}
            disableClearable={true}
            required={true}
            onLoading={setSaveDisabled}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            margin="normal"
            fullWidth
            error={_.isArray(errors.Name)}
            helperText={
              _.isArray(errors.Name) && errors.Name.join()
            }
            disabled={loading}
            label="Name"
            value={entry.name}
            required={true}
            onChange={(event) => onInputChange(event, "name")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            margin="normal"
            fullWidth
            error={_.isArray(errors.Description)}
            helperText={
              _.isArray(errors.Description) && errors.Description.join()
            }
            disabled={loading}
            label="Description"
            value={entry.description}
            required={true}
            onChange={(event) => onInputChange(event, "description")}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
