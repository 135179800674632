import React, { useContext } from "react";

import Grid from "@mui/material/Grid";
import { auditLogEndpoint, auditLogEntitiesEndpoint, auditLogOperationsEndpoint, auditLogUsersEndpoint, isAuthorizedEndpoint } from "../../Services/apiEndpoint";
import { errorManagement } from "../../Services/errorManagement";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import MyTable from "../General/Pagination/MyTable";
import Entry from "./Entry";

export default function List(props) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [refresh, setRefresh] = React.useState(0);

  const tableOptions = {
    columns: [
      {
        name: "RequestReceivedAt",
        description: "Date",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 3,
      },
      {
        name: "UserId",
        description: "User",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 3,
      },
      {
        name: "IpAddress",
        description: "IP Address",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2
      },
      {
        name: "Operation",
        description: "Operation",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2
      },
      {
        name: "Entity",
        description: "Entity",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2
      }
    ],
    defaultSort: [
      { name: "RequestReceivedAt", order: "-" }],
    additionalFilters: [
      {
        name: "RequestReceivedAt",
        description: "Log Period",
        filter: {
          enabled: true,
          name: "RequestReceivedAtRange",
          comparison: "==",
          type: "dateRange",
          defaultValue: -1,
          options: {
            addNullPeriod: true,
          },
        },
        size: 3,
      },
      {
        name: "UserId",
        description: "User",
        filter: {
          enabled: true,
          required: false,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: auditLogUsersEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "IpAddress",
        description: "IP Address",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "IP Address",
        },
        size: 2
      },
      {
        name: "Operation",
        description: "Operation",
        filter: {
          enabled: true,
          required: false,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: auditLogOperationsEndpoint,
            liveOptions: false,
          },
        },
        size: 2
      },
      {
        name: "Entity",
        description: "Entity",
        filter: {
          enabled: true,
          required: false,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: auditLogEntitiesEndpoint,
            liveOptions: false,
          },
        },
        size: 2
      }
    ]
  };

  React.useEffect(() => {
    api
      .get(isAuthorizedEndpoint)
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Audit Logs",
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <MyTable
            options={tableOptions}
            url={`${auditLogEndpoint}`}
            refresh={refresh}
            label={"Audit Logs"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
