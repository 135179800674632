import {Button, Grow, IconButton, Slider, Typography,} from "@mui/material";
import React, {useRef, useState} from "react";

import AvatarEditor from "react-avatar-editor";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomAvatar from "../General/CustomAvatar";
import Dropzone from "react-dropzone";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dragAndDropArea: {
    borderColor: theme.palette.secondary.main,
    borderStyle: "dashed",
    paddingTop: 10,
    paddingLeft: 10,
    width: 175,
    height: 175,
  },
  dragAndDropIndicator: {
    color: theme.palette.secondary.main,
    width: 150,
    height: 150,
    textAlign: "center",
  },
  openState: { justifyContent: "center", height: "100%" },
}));

const initialState = {
  text: "",
  picture: "",
  pictureChanged: false,
  oldPicture: "",
  editPicture: "",
  pictureScale: 1,
  openEditPicture: false,
};

export default function UserPicture({
  picture,
  text = "",
  onAccept = null,
  onEditingPicture = null,
  disabled = false,
}) {
  const classes = useStyles();
  const [state, setState] = useState({
    ...initialState,
    text: text,
    picture: picture,
    oldPicture: picture,
  });

  const [avatarEditorRef, setAvatarEditorRef] = useState(null);
  const setEditorRef = (editor) => setAvatarEditorRef(editor);
  const maxPictureScale = 20;
  const minPictureScale = 1;

  React.useEffect(() => {
    if (onEditingPicture) onEditingPicture(state.openEditPicture);
  }, [onEditingPicture, state.openEditPicture]);

  const setPictureScale = (value) =>
    setState({ ...state, pictureScale: value });

  const increasePictureScale = () =>
    setState((prevState) => ({
      ...prevState,
      pictureScale:
        prevState.pictureScale !== maxPictureScale
          ? prevState.pictureScale + 1
          : prevState.pictureScale,
    }));

  const decreasePictureScale = () =>
    setState((prevState) => ({
      ...prevState,
      pictureScale:
        prevState.pictureScale !== minPictureScale
          ? prevState.pictureScale - 1
          : prevState.pictureScale,
    }));

  const setOpenEditPicture = (value) => {
    setState({ ...state, openEditPicture: value });
  };

  const dropzoneRef = useRef();
  const openDropzoneDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleDrop = (dropped) => {
    setPictureScale(1);
    setState({ ...state, editPicture: dropped[0] });
  };

  const onImageChange = () => {
    if (avatarEditorRef && state.editPicture !== "") {
      const canvasScaled = avatarEditorRef.getImageScaledToCanvas();
      setState({ ...state, picture: canvasScaled.toDataURL() });
    }
  };

  const editPicture = () => {
    setOpenEditPicture(true);
  };

  const cancelEditPicture = () => {
    setState({
      ...state,
      picture: state.oldPicture,
      editPicture: "",
      openEditPicture: false,
    });
  };

  const acceptEditPicture = () => {
    setOpenEditPicture(false);
    if (onAccept) onAccept(state.picture);
  };

  return state === null ? null : (
    <Grid container spacing={2} justifyContent="center">
      <Grid item sm={6}>
        <Grid
          container
          spacing={2}
          direction={"column"}
          alignItems="center"
          className={state.openEditPicture ? classes.openState : null}
        >
          <Grid item>
            <CustomAvatar
              text={state.text}
              picture={state.picture}
              size="large"
            />
          </Grid>
          <Grid
            item
            style={{ display: !state.openEditPicture ? "block" : "none" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={editPicture}
              startIcon={<EditIcon />}
              disabled={disabled}
            >
              Edit
            </Button>
          </Grid>
          <Grid
            item
            style={{ display: state.openEditPicture ? "block" : "none" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={acceptEditPicture}
              startIcon={<CheckCircleIcon />}
            >
              Accept
            </Button>
          </Grid>
          <Grid
            item
            style={{ display: state.openEditPicture ? "block" : "none" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={cancelEditPicture}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grow in={state.openEditPicture} mountOnEnter unmountOnExit>
        <Grid item sm={6}>
          <Grid container spacing={2}>
            <Grid item>
              <Dropzone
                accept={["image/png", "image/jpeg"]}
                ref={dropzoneRef}
                onDrop={handleDrop}
                noClick
                noKeyboard
                style={{ width: "110px", height: "110px" }}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()} className={classes.dragAndDropArea}>
                    {state.editPicture !== "" && (
                      <AvatarEditor
                        ref={setEditorRef}
                        width={100}
                        height={100}
                        borderRadius={200}
                        scale={state.pictureScale}
                        image={state.editPicture}
                        onLoadSuccess={onImageChange}
                        onImageChange={onImageChange}
                      />
                    )}
                    <input {...getInputProps()} />
                    {state.editPicture === "" && (
                      <div className={classes.dragAndDropIndicator}>
                        <ImageSearchIcon fontSize={"large"} />
                        <br />
                        <Typography variant={"body2"}>
                          {isDragActive
                            ? "Drop the image here"
                            : 'Drag and drop an image here, or click the "Select image" button to upload an image.'}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={"column"}
                style={{ height: "100%" }}
                alignItems={"center"}
              >
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={increasePictureScale}
                    disabled={
                      state.editPicture === "" ||
                      state.pictureScale === maxPictureScale
                    }
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Grid>
                <Grid item xs style={{ paddingBottom: 10 }}>
                  <Slider
                    value={state.pictureScale}
                    disabled={state.editPicture === ""}
                    min={minPictureScale}
                    max={maxPictureScale}
                    orientation="vertical"
                    onChange={(event, newValue) => setPictureScale(newValue)}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={decreasePictureScale}
                    disabled={
                      state.editPicture === "" ||
                      state.pictureScale === minPictureScale
                    }
                  >
                    <ZoomOutIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Button
                size="small"
                onClick={openDropzoneDialog}
                variant="outlined"
                color="primary"
                startIcon={<ImageSearchIcon />}
              >
                Select image...
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );
}
