import makeStyles from '@mui/styles/makeStyles';
import { Edit, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Grid, IconButton, Collapse, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { GeDeleteIcon } from "../General/GeneralIcons";
import { useContext } from 'react';
import { HomeAppContext } from '../Home';
import EquivalencyList from './EquivalencyList';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20,
  },
  clientLabel: {
    fontWeight: 'bold',
    marginRight: '5px'
  },
  shortName: {
    paddingLeft: '0!important',
    marginLeft: '-10px'
  }
}));


const Entry = ({ entry, onEdit, onDelete, refresh }) => {
  const classes = useStyles();
  const { homeState } = useContext(HomeAppContext);
  const [show, setShow] = useState(false);

  const isAdmin = homeState.userProfile.role === "Admin";
  const isPowerUser = homeState.userProfile.role === "PowerUser";

  const toggle = () => {
    setShow((show) => !show);
  };
  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };
  const handleClickDelete = (event) => {
    onDelete(event, entry);
  };

  return (
    <Grid item sm={12}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item sm={3}>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                <React.Fragment>
                  {show ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </React.Fragment>
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2">{entry.tool.text}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={!(isAdmin || isPowerUser) ? 4 : 3}>
          {entry.name}
        </Grid>
        <Grid item sm={2}>
          {entry.identifier}
        </Grid>
        <Grid item sm={1}>
          {entry.code}
        </Grid>
        <Grid item sm={1}>
          {entry.iso639Dash1}
        </Grid>
        <Grid item sm={1}>
          {entry.iso639Dash2}
        </Grid>
        {(isAdmin || isPowerUser) && (
          <Grid item xs={12} md={1} style={{ textAlign: "center" }}>
            <IconButton
              size={"small"}
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={handleClickEdit}
              title="Edit"
            >
              <Edit fontSize="inherit" />
            </IconButton>
            {isAdmin && (
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="delete"
                component="span"
                onClick={handleClickDelete}
                title="Delete"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            )}
          </Grid>)}
      </Grid>
      <Grid container alignItems={"center"}>
        <Grid item sm={12}>
          <Collapse in={show} mountOnEnter unmountOnExit>
            <Box className={classes.detailsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Typography variant='span' className={classes.clientLabel}>Applies To:</Typography>
                  <Typography variant='span'>{entry.appliesTo.text}</Typography>
                </Grid>
                <Grid item sm={3} className={classes.shortName}>
                  <Typography variant='span' className={classes.clientLabel}>Short Name:</Typography>
                  <Typography variant='span'>{entry.shortName || '-'}</Typography>
                </Grid>
                {entry.writingDirection &&
                  <Grid item sm={3} className={classes.shortName}>
                    <Typography variant='span' className={classes.clientLabel}>Writing Direction:</Typography>
                    <Typography variant='span'>{entry.writingDirection.text || '-'}</Typography>
                  </Grid>
                }
                {entry.writingSystem &&
                  <Grid item sm={3} className={classes.shortName}>
                    <Typography variant='span' className={classes.clientLabel}>Writing System:</Typography>
                    <Typography variant='span'>{entry.writingSystem.text || '-'}</Typography>
                  </Grid>
                }
                {entry.xtrfClient != null && entry.xtrfClient?.id !== 0 &&
                  <Grid item sm={12}>
                    <Typography variant='span' className={classes.clientLabel}>XTRF Client:</Typography>
                    <Typography variant='span'>{entry.xtrfClient?.text}</Typography>
                  </Grid>
                }
                {entry.tmsClient != null && entry.tmsClient?.id !== 0 &&
                  <Grid item sm={12}>
                    <Typography variant='span' className={classes.clientLabel}>TMS Client:</Typography>
                    <Typography variant='span'>{entry.tmsClient?.text}</Typography>
                  </Grid>
                }
                {entry.salesforceClient != null && entry.salesforceClient?.id !== '0' && entry.salesforceClient?.id !== '' &&
                  <Grid item sm={12}>
                    <Typography variant='span' className={classes.clientLabel}>Salesforce Client:</Typography>
                    <Typography variant='span'>{entry.salesforceClient?.text}</Typography>
                  </Grid>
                }
                {entry.equivalencyCount > 0 &&
                  <Grid item sm={12}>
                    <EquivalencyList
                      languageId={entry.id}
                      refresh={refresh}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Entry;
