export const MsalTenants = {
    Default: ""
}


const msalConfig = (tenant = MsalTenants.Default) => {
    switch (tenant) {
        case MsalTenants.Default:
            return {
                auth: {
                    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
                    authority: process.env.REACT_APP_MICROSOFT_TENANT_ID,
                    postLogoutRedirectUri: window.location.origin + "/logout",
                    redirectUri: window.location.origin + "/login",
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: true
                }
            }
        default:
            throw "Tenant not supported"
    }

};
export default msalConfig;
