import React from "react";

import Grid from "@mui/material/Grid";
import MyTable from "../General/Pagination/MyTable";
import { appliesToEndpoint, equivalenciesEndpoint, toolsDropdownEndpoint } from "../../Services/apiEndpoint";
import EquivalencyEntry from "./EquivalencyEntry";
import { Typography } from "@mui/material";

export default function EquivalencyList({ languageId, refresh }) {

  const tableOptions = {
    columns: [
      {
        name: "Tool",
        description: "Tool",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "AppliesTo",
        description: "Applies to",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "Name",
        description: "Language",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "Identifier",
        description: "Identifier",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "Code",
        description: "Code",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 1,
      },
      {
        name: "Iso639Dash1",
        description: "ISO 639-1",
        sortable: false,
        filter: {
          enabled: false,
        },
        size: 1,
      },
      {
        name: "Iso639Dash2",
        description: "ISO 639-2",
        sortable: false,
        filter: {
          enabled: false,
        },
        size: 1,
      },
    ],
    defaultSort: [
      { name: "Tool", order: "" },
      { name: "Name", order: "" }
    ],
    additionalFilters: [
      {
        name: "Tool",
        description: "Tool",
        filter: {
          enabled: true,
          required: false,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: toolsDropdownEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "AppliesTo",
        description: "Applicable To",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: appliesToEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "Name",
        description: "Language",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Language",
        },
        size: 3,
      },
      {
        name: "Identifier",
        description: "Identifier",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Identifier",
        },
        size: 3,
      }
    ],
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h6">Equivalencies</Typography>
        </Grid>
        <Grid item xs={12}>
          <MyTable
            options={tableOptions}
            rowsPerPage={5}
            url={`${equivalenciesEndpoint}/${languageId}`}
            refresh={refresh}
            label={"Languages"}
            renderItem={(item, index) => (
              <EquivalencyEntry
                key={index}
                entry={item}
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
