import { Box, CircularProgress, Collapse, IconButton, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import moment from "moment-timezone";
import { defaultDateTimeFormat } from "../../Services/constantsAndTools";
import CustomAvatar from "../General/CustomAvatar";
import { makeStyles } from "@mui/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import api from "../../Services/api";
import { auditLogEndpoint } from "../../Services/apiEndpoint";
import { useContext } from "react";
import { HomeAppContext } from "../Home";
import ChangeLog from "./ChangeLog";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginTop: "-12px"
  },
  loader: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20,
  },
  detailLabel: {
    fontWeight: 'bold',
    marginRight: '5px',
    display: "block"
  },
  detail: {
    display: "block",
    marginLeft: "10px"
  },
  paddingTop0: {
    paddingTop: "0!important"
  },
  changeLogHeader: {
    fontSize: "15px",
    fontWeight: "bold"
  }
}));
const Entry = ({
  entry
}) => {
  const classes = useStyles();

  const { homeDispatch } = useContext(HomeAppContext);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entryDetail, setEntryDetail] = useState({});

  const toggle = () => {
    setExpanded((expanded) => !expanded);
    if (expanded) {
      return;
    }

    setIsLoading(true);
    api.get(`${auditLogEndpoint}/${entry.id}`)
      .then((response) => {
        setIsLoading(false);
        setEntryDetail(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setExpanded((expanded) => !expanded);
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: 'Error loading log details', type: "warning" },
        });
      });
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} sm={10} md={3}>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                <React.Fragment>
                  {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </React.Fragment>
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="inline">
                {moment
                  .utc(entry.requestReceivedAt)
                  .local()
                  .format(defaultDateTimeFormat)}
              </Typography>
            </Grid>
          </Grid>


        </Grid>
        <Grid item xs={12} sm={10} md={3}>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item className={classes.avatar}>
              <CustomAvatar
                text={entry.user?.fullName}
                picture={entry.user?.picture}
              />
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="caption" display="block" noWrap>
                {entry.user?.fullName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} md={2}>
          <Typography variant="span" display="inline">
            {entry.ipAddress}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={2}>
          <Typography variant="span" display="inline">
            {entry.operation}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={2}>
          <Typography variant="span" display="inline">
            {entry.entity}
          </Typography>
        </Grid>
      </Grid>
      {expanded &&
        <Grid container spacing={2}>
          {isLoading &&
            <Grid item xs={12} className={classes.loader}>
              <Grid container justifyContent={"center"}>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          }
          <Grid item sm={12}>
            <Collapse in={expanded} mountOnEnter unmountOnExit>
              <Box className={classes.detailsContainer}>
                <Grid container spacing={2}>
                  <Grid item sm={1}>
                    <Typography variant='span' className={classes.detailLabel}>Entity Id</Typography>
                    <Typography variant='span' className={classes.detail}>{entryDetail.entityId || '#N/A'}</Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Typography variant='span' className={classes.detailLabel}>Method</Typography>
                    <Typography variant='span' className={classes.detail}>{entryDetail.requestMethod}</Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant='span' className={classes.detailLabel}>Resource URL</Typography>
                    <Typography variant='span' className={classes.detail}>{entryDetail.requestPath}</Typography>
                  </Grid>

                  <Grid item sm={7}>
                    <Typography variant='span' className={classes.detailLabel}>Query String</Typography>
                    <Typography variant='span' className={classes.detail}>{entryDetail.queryString || '#N/A'}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Grid>
          {entryDetail.changeLogs && entryDetail.changeLogs.length > 0 &&
            <Grid item sm={12} className={classes.paddingTop0}>
              <Grid container spacing={2} className={`${classes.detailsContainer} ${classes.paddingTop0}`}>
                <Grid item sm={12} >
                  <Typography variant="div" className={classes.changeLogHeader}>Change Log</Typography>
                </Grid>
                <Grid item sm={12}>
                  <ChangeLog changeLogs={entryDetail.changeLogs} />
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      }
    </Grid>
  );
};

export default Entry;
