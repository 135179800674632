import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { appliesToEndpoint, languagesEndpoint, toolsDropdownEndpoint } from "../../../Services/apiEndpoint";
import MyTable from "../../General/Pagination/MyTable";
import EquivalencyEntry from "./EquivalencyEntry";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "90vh!important",
    maxHeight: "90vh!important",
  },
}));

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddEquivalenciesDialog({
  entry = null,
  open,
  setOpen,
  equivalencies = [],
  onAddEquivalencies,
}) {
  const classes = useStyles();

  const [selectedItems, _setSelectedItems] = React.useState([]);

  const setSelectedItems = (items) => {
    _setSelectedItems(items.reduce((acc, x) => [...acc, x.id], []));
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAdd = () => {
    setOpen(false);
    onAddEquivalencies(selectedItems);
  };

  const tableOptions = {
    columns: [
      {
        name: "Tool",
        description: "Tool",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 3,
      },
      {
        name: "AppliesTo",
        description: "Applies to",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 3,
      },
      {
        name: "Name",
        description: "Language",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "Identifier",
        description: "Identifier",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "Code",
        description: "Code",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      }
    ],
    additionalFilters: [
      {
        name: "Tool",
        description: "Tool",
        filter: {
          enabled: true,
          required: false,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: toolsDropdownEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "AppliesTo",
        description: "Applicable To",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: appliesToEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "Name",
        description: "Language",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Language",
        },
        size: 3,
      },
      {
        name: "Identifier",
        description: "Identifier",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Identifier",
        },
        size: 3,
      }
    ]
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={handleClose}
      TransitionComponent={transition}
      aria-labelledby="alert-dialog-edit-title"
    >
      <DialogTitle id="alert-dialog-edit-title">{`Add Equivalencies: ${entry.name}(${entry.identifier})`}</DialogTitle>
      <DialogContent>
        <MyTable
          options={tableOptions}
          multiSelect={true}
          filterOutIds={equivalencies.map((x) => x.id)}
          onSelectedItemsChange={setSelectedItems}
          url={`${languagesEndpoint}/except/${entry.id ?? 0}`}
          label={"Languages"}
          renderItem={(item, index) => (
            <EquivalencyEntry key={item.id} entry={item} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleCloseAdd} disabled={selectedItems.length === 0}>
          Add Selected Languages
        </Button>
      </DialogActions>
    </Dialog>
  )
}