import { Divider, Grid, IconButton } from "@mui/material";
import React from "react";
import { TpRemoveIcon } from "../../General/GeneralIcons";

const MappedEquivalencyEntry = ({ entry, equivalency, handleRemove }) => {

  const handleClickRemove = () => {
    handleRemove(equivalency);
  };

  return (
    <React.Fragment>
      <Grid item sm={12}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item sm={2}>
            {equivalency.tool.text}
          </Grid>
          <Grid item sm={2}>
            {equivalency.appliesTo.text}
          </Grid>
          <Grid item sm={3}>
            {equivalency.name}
          </Grid>
          <Grid item sm={3}>
            {equivalency.identifier}
          </Grid>
          <Grid item sm={1}>
            {equivalency.code}
          </Grid>
          <Grid item sm={1}>
            <IconButton
              size={"small"}
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={handleClickRemove}
              title="Remove"
            >
              <TpRemoveIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
    </React.Fragment>
  );
};

export default MappedEquivalencyEntry;
