import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import Create from "./Create";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import MyTable from "../General/Pagination/MyTable";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { appliesToEndpoint, isAuthorizedEndpoint, languagesEndpoint, toolsDropdownEndpoint } from "../../Services/apiEndpoint";
import ConfirmDialog from "../General/ConfirmDialog";
import Edit from "./Edit";
import { UploadFile } from "@mui/icons-material";
import Upload from "./Upload";
import PreflightSummary from "./PreflightSummary";
import ImportSummary from "./ImportSummary";

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [deleteParams, setDeleteParams] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [bulkDelete, setBulkDelete] = React.useState(false);
  const [openPreflight, setOpenPreflight] = React.useState(false);
  const [preflightSummary, setPreflightSummary] = React.useState({});
  const [uploadParams, setUploadParams] = React.useState({});
  const [openSummary, setOpenSummary] = React.useState(false);
  const [importSummary, setImportSummary] = React.useState({});
  const [processNotification, setProcessNotification] = React.useState({});

  const isAdmin = homeState.userProfile.role === "Admin";
  const isPowerUser = homeState.userProfile.role === "PowerUser";

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleBulkUpload = (event) => {
    setOpenUpload(true);
  };

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickConfirmDelete = (event, entry) => {
    setDeleteParams({ id: entry.id });
    setBulkDelete(entry.uploadedFromId > 0);
    setOpenDelete(true);
  };
  const cancelDelete = () => {
    setDeleteParams(null);
  };

  const acceptDelete = (params) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Deleting", loading: true },
    });
    api.delete(`${languagesEndpoint}/${params.id}/${params.additionalConfirm || false}`)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: `${response.data} Language${parseInt(response.data) > 1 ? 's' : ''} Deleted`, type: "success" },
        });
        refreshItems();
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const tableOptions = {
    columns: [
      {
        name: "Tool",
        description: "Tool",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 3,
      },
      {
        name: "Name",
        description: "Language",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: !(isAdmin || isPowerUser) ? 4 : 3,
      },
      {
        name: "Identifier",
        description: "Identifier",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 2,
      },
      {
        name: "Code",
        description: "Code",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 1,
      },
      {
        name: "Iso639Dash1",
        description: "ISO 639-1",
        sortable: false,
        filter: {
          enabled: false,
        },
        size: 1,
      },
      {
        name: "Iso639Dash2",
        description: "ISO 639-2",
        sortable: false,
        filter: {
          enabled: false,
        },
        size: 1,
      },
    ],
    defaultSort: [
      { name: "Tool", order: "" },
      { name: "Name", order: "" }
    ],
    additionalFilters: [
      {
        name: "Tool",
        description: "Tool",
        filter: {
          enabled: true,
          required: false,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: toolsDropdownEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "AppliesTo",
        description: "Applicable To",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: appliesToEndpoint,
            liveOptions: false,
          },
        },
        size: 3,
      },
      {
        name: "Name",
        description: "Language",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Language",
        },
        size: 3,
      },
      {
        name: "Identifier",
        description: "Identifier",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Identifier",
        },
        size: 3,
      }
    ],
  };

  React.useEffect(() => {
    api
      .get(isAuthorizedEndpoint)
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Languages",
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} >
        {(isAdmin || isPowerUser) && (
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New Language
            </Button>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBulkUpload}
              startIcon={<UploadFile />}
            >
              Upload Languages
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MyTable
            options={tableOptions}
            url={languagesEndpoint}
            refresh={refresh}
            label={"Languages"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                onEdit={handleClickEdit}
                onDelete={handleClickConfirmDelete}
                refresh={refresh}
              />
            )}
          />
        </Grid>
      </Grid>
      {(isAdmin || isPowerUser) && (
        <React.Fragment>
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
        </React.Fragment>
      )}
      {isAdmin && (
        <React.Fragment>
          <ConfirmDialog
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Delete Language"}
            message={"Are you sure that you want to delete this language?"}
            onCloseSuccess={acceptDelete}
            successParams={deleteParams}
            onClose={cancelDelete}
            additionalConfirm={bulkDelete}
            additionalConfirmMessage={"Delete all other mappings that were uploaded with this specific mapping"}
          />
          <Upload
            open={openUpload}
            setOpen={setOpenUpload}
            openSummary={setOpenPreflight}
            setResponse={setPreflightSummary}
            setUploadParams={setUploadParams}
            setNotification={setProcessNotification}
          />
          <PreflightSummary
            open={openPreflight}
            setOpen={setOpenPreflight}
            summary={preflightSummary}
            uploadParams={uploadParams}
            setResponse={setImportSummary}
            openSummary={setOpenSummary}
            processNotification={processNotification}
            setNotification={setProcessNotification}
          />
          <ImportSummary
            open={openSummary}
            setOpen={setOpenSummary}
            summary={importSummary}
            setUpload={setOpenUpload}
            onUpload={refreshItems}
            processNotification={processNotification}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
