import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import CustomAvatar from "../../General/CustomAvatar";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  tooltipWidth: {
    maxWidth: 400,
  },
  simpleIcon: { verticalAlign: "sub", fontSize: 18 },
  currency: { textAlign: "right" },
  infoIcon: {
    color: theme.palette.secondary.light,
    marginRight: 2,
    verticalAlign: "middle",
    fontSize: "inherit",
  },
  allSmallIcon: {
    verticalAlign: "middle",
    fontSize: "inherit",
    marginRight: 4,
  },
  inactive: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  ChipSmall: {
    fontSize: 12,
    marginLeft: 5,
    paddingTop: 1,
    height: "auto",
  },
}));

const Entry = ({
  entry,
  isAdmin = false,
  onEdit,
  onSendReset,
}) => {
  const classes = useStyles();

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };
  const handleClickSendReset = (event) => {
    onSendReset(event, entry);
  };

  return (
    <Grid item xs={12} className={clsx({ [classes.inactive]: !entry.active })}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item sm={5}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CustomAvatar
                text={entry.fullName}
                picture={entry.picture}
                size={"normal"}
              />
            </Grid>
            <Grid
              item
              style={{
                flex: 1,
                minWidth: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item>
                <Typography variant="body2" display="inline">
                  {entry.fullName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4}>
          {entry.email}
        </Grid>
        <Grid item md={isAdmin ? 2 : 3} xs={12}>
          {entry.role && entry.role.description}
        </Grid>

        {isAdmin && (
          <Grid item xs={12} md={1} style={{ textAlign: "center" }}>
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit user"
                component="span"
                onClick={handleClickEdit}
                title="Edit user"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              {entry.active && entry.type === "Internal" && (
                <IconButton
                  size={"small"}
                  color="secondary"
                  title="Send reset password email"
                  aria-label="send reset email"
                  component="span"
                  onClick={handleClickSendReset}
                >
                  <EmailIcon fontSize="inherit" />
                </IconButton>
              )}
            </React.Fragment>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Entry;
