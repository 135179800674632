import { Grid } from "@mui/material";
import React from "react";

const EquivalencyEntry = ({ entry }) => {
  return (
    <Grid item sm={12}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item sm={3}>
          {entry.tool.text}
        </Grid>
        <Grid item sm={3}>
          {entry.appliesTo.text}
        </Grid>
        <Grid item sm={2}>
          {entry.name}
        </Grid>
        <Grid item sm={2}>
          {entry.identifier}
        </Grid>
        <Grid item sm={2}>
          {entry.code}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EquivalencyEntry;
