import { Avatar, Collapse } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {
    GeAuditLog,
    GeLanguageIcon,
    GeTool,
    GeToolGroup,
    GeUsersIcon,
} from "./General/GeneralIcons";
import React, { useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { userStore } from "../Services/store";

const useStyles = makeStyles((theme) => ({
    active: {
        borderLeftStyle: "solid",
        borderLeftWidth: "4px",
        borderLeftColor: theme.palette.secondary.main,
        paddingLeft: "12px",
    },
    activeMenu: {
        borderLeftStyle: "solid",
        borderLeftWidth: "2px",
        borderLeftColor: theme.palette.secondary.main,
        paddingLeft: "14px",
    },
    avatarIcon: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
        width: 24,
    },
    nested: {
        paddingLeft: theme.spacing(3),
    },
    nestedActive: {
        paddingLeft: theme.spacing(3) - 4,
    },
    listItemIcon: {
        minWidth: 40,
    },
}));

const RouteType = {
    Internal: "internal",
    External: "external",
    Action: "action",
    Menu: "menu",
};

const onHangfireUrl = () => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = `${process.env.REACT_APP_BACKEND_URL}/hangfire?access_token=${userStore.token}`;
    a.click();
};

const HangFireIcon = withStyles((theme) => ({
    root: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
        width: 24,
    },
}))(Avatar);

const adminRoutes = [
    {
        text: "Languages",
        type: RouteType.Internal,
        to: "/languages",
        icon: <GeLanguageIcon />,
    },
    {
        text: "Users",
        type: RouteType.Internal,
        to: "/users",
        icon: <GeUsersIcon />,
    },
    {
        text: "Tools",
        type: RouteType.Internal,
        to: "/tools",
        icon: <GeTool />,
    },
    {
        text: "Tool Groups",
        type: RouteType.Internal,
        to: "/tool-groups",
        icon: <GeToolGroup />,
    },
    {
        text: "Audit Logs",
        type: RouteType.Internal,
        to: "/audit-logs",
        icon: <GeAuditLog />,
    }
];

const powerUserRoutes = [
    {
        text: "Languages",
        type: RouteType.Internal,
        to: "/languages",
        icon: <GeLanguageIcon />,
    },
    {
        text: "Users",
        type: RouteType.Internal,
        to: "/users",
        icon: <GeUsersIcon />,
    },
    {
        text: "Tools",
        type: RouteType.Internal,
        to: "/tools",
        icon: <GeTool />,
    },
    {
        text: "Tool Groups",
        type: RouteType.Internal,
        to: "/tool-groups",
        icon: <GeToolGroup />,
    }
];

const userRoutes = [
    {
        text: "Languages",
        type: RouteType.Internal,
        to: "/languages",
        icon: <GeLanguageIcon />,
    },
    {
        text: "Tools",
        type: RouteType.Internal,
        to: "/tools",
        icon: <GeTool />,
    },
    {
        text: "Tool Groups",
        type: RouteType.Internal,
        to: "/tool-groups",
        icon: <GeToolGroup />,
    },
];

const RenderInternal = ({
    item,
    closeDrawer,
    nested = false,
    menuOpen = true,
}) => {
    const classes = useStyles();
    const activeClass = nested
        ? clsx(classes.active, classes.nestedActive)
        : classes.active;
    const inactiveClass = nested ? classes.nested : null;
    const history = useHistory();

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={RouterLink}
                onClick={closeDrawer}
                to={item.to}
                className={
                    history.location.pathname.includes(item.to)
                        ? activeClass
                        : inactiveClass
                }
            >
                <ListItemIcon className={classes.listItemIcon} title={item.text}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={menuOpen ? item.text : ""}
                    primaryTypographyProps={{ variant: "body2" }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const RenderExternal = ({ item, nested = false, menuOpen = true }) => {
    const classes = useStyles();

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={"a"}
                href={item.to}
                rel="noreferrer"
                target="_blank"
                className={nested ? classes.nested : null}
            >
                <ListItemIcon className={classes.listItemIcon} title={item.text}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={menuOpen ? item.text : ""}
                    primaryTypographyProps={{ variant: "body2" }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const RenderAction = ({ item, nested = false, menuOpen = true }) => {
    const classes = useStyles();
    const onClick = (event) => {
        if (item.action !== null) item.action();
    };

    return (
        <ListItem disablePadding>
            <ListItemButton
                onClick={onClick}
                className={nested ? classes.nested : null}
            >
                <ListItemIcon className={classes.listItemIcon} title={item.text}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={menuOpen ? item.text : ""}
                    primaryTypographyProps={{ variant: "body2" }}
                />
            </ListItemButton>
        </ListItem>
    );
};

const RenderMenu = ({ item, closeDrawer, menuOpen = true }) => {
    const classes = useStyles();
    const history = useHistory();

    const isOpen = (item) => {
        return item.items.some((x) =>
            x.type === RouteType.Menu ? isOpen(x) : history.location.pathname === x.to
        );
    };
    const [open, setOpen] = React.useState(isOpen(item));

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <React.Fragment>
            <ListItem
                button
                onClick={handleClick}
                className={isOpen(item) ? classes.activeMenu : null}
            >
                <ListItemIcon className={classes.listItemIcon} title={item.text}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={menuOpen ? item.text : ""}
                    primaryTypographyProps={{ variant: "body2" }}
                />
                {menuOpen && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.items.map((subItems, index) =>
                        subItems.type === RouteType.Menu ? (
                            <RenderMenu
                                key={index}
                                item={subItems}
                                closeDrawer={closeDrawer}
                                menuOpen={menuOpen}
                            />
                        ) : subItems.type === RouteType.Internal ? (
                            <RenderInternal
                                key={index}
                                item={subItems}
                                closeDrawer={closeDrawer}
                                nested={true}
                                menuOpen={menuOpen}
                            />
                        ) : (
                            <RenderExternal key={index} item={subItems} nested={true} />
                        )
                    )}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export default function Sidebar({
    userProfile,
    handleDrawerClose = null,
    menuOpen = true,
}) {
    const [role, setRole] = useState("");

    const closeDrawer = () => {
        if (handleDrawerClose !== null) handleDrawerClose();
    };

    useEffect(() => {
        if (userProfile !== null && userProfile !== undefined) {
            setRole(userProfile.role);
        } else {
            setRole(null);
        }
    }, [userProfile]);

    const RenderRoutes = ({ items }) => (
        <React.Fragment>
            {items.map((item, index) =>
                item.type === RouteType.Menu ? (
                    <RenderMenu
                        key={index}
                        item={item}
                        closeDrawer={closeDrawer}
                        menuOpen={menuOpen}
                    />
                ) : item.type === RouteType.Internal ? (
                    <RenderInternal
                        key={index}
                        item={item}
                        closeDrawer={closeDrawer}
                        menuOpen={menuOpen}
                    />
                ) : item.type === RouteType.External ? (
                    <RenderExternal key={index} item={item} menuOpen={menuOpen} />
                ) : (
                    <RenderAction key={index} item={item} menuOpen={menuOpen} />
                )
            )}
        </React.Fragment>
    );

    return (
        <List component="nav">
            {
                {
                    Admin: <RenderRoutes items={adminRoutes} />,
                    PowerUser: <RenderRoutes items={powerUserRoutes} />,
                    User: <RenderRoutes items={userRoutes} />
                }[role]
            }
        </List>
    );
}
