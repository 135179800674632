import {
  Badge,
  CircularProgress,
  Grow,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import NotificationVariation from "../../Services/constantsAndTools";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import api from "../../Services/api";
import { green,blue,red,yellow} from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  taskButton: {
    position: "absolute",
    right: 20,
    bottom: 15,
    backgroundColor: theme.palette.background.paper,
    zIndex: 4,
  },
  sidePanel: {
    position: "absolute",
    right: 20,
    bottom: 15,
    width: 400,
    height: 250,
    zIndex: 4,
  },
  sidePanelMini: {
    position: "absolute",
    right: 20,
    bottom: 15,
    width: 400,
    height: 60,
    overflow: "hidden",
  },
  flexScroll: {
    height: "0px",
    flex: "1 1 auto",
    overflowY: "scroll",
    minHeight: "190px",
  },
  secondaryText: {
    marginTop: 5,
  },
  success: {
    color: green[500],
  },
  info: {
    color: blue[500],
  },
  warning: {
    color: yellow[500],
  },
  error: {
    color: red[500],
  },
  items: {
    width: "100%",
    wordBreak: "break-word",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
  taskInternalButton: {},
  fabInternalProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
}));

const NormalBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: -5,
    padding: "0 4px",
    color: "white",
    backgroundColor: blue[500],
  },
}))(Badge);

const SuccessBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: -5,
    padding: "0 4px",
    color: "white",
    backgroundColor: green[500],
  },
}))(Badge);

const ErrorBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: -5,
    padding: "0 4px",
    color: "white",
    backgroundColor: red[500],
  },
}))(Badge);

const TypeIcon = ({ type }) => {
  const classes = useStyles();
  return {
    success: <CheckCircleIcon className={classes.success} />,
    info: <InfoIcon className={classes.info} />,
    warning: <WarningIcon className={classes.warning} />,
    error: <ErrorIcon className={classes.error} />,
  }[type];
};

const Task = ({ task }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const lastMessage = task.messages[task.messages.length - 1];
  const [canceling, setCanceling] = useState(false);

  const cancelTask = (url) => {
    setCanceling(true);
    api.get(url).then((response) => {});
  };

  const downloadFile = (url) => {
    window.location = url;
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemIcon>
          {lastMessage.progress !== 100 ? (
            lastMessage.cancelUrl !== null && !canceling ? (
              <IconButton
                className={classes.taskInternalButton}
                component={Paper}
                onClick={(e) => cancelTask(lastMessage.cancelUrl)}
                size="large">
                <ClearIcon />

                <CircularProgress
                  size={50}
                  className={classes.fabInternalProgress}
                />
              </IconButton>
            ) : (
              <CircularProgress size={50} />
            )
          ) : (
            <TypeIcon type={lastMessage.type} />
          )}
        </ListItemIcon>
        <ListItemText
          disableTypography={true}
          primary={<React.Fragment>{lastMessage.name}</React.Fragment>}
          secondary={
            <Grid container spacing={1} className={classes.secondaryText}>
              <Grid item sm={12}>
                <Typography variant={"caption"}>
                  {lastMessage.message}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <LinearProgress
                  variant="determinate"
                  value={lastMessage.progress}
                />
              </Grid>
            </Grid>
          }
        />
        {lastMessage.progress === 100 && lastMessage.downloadUrl !== null ? (
          <IconButton
            size="small"
            onClick={(e) => downloadFile(lastMessage.downloadUrl)}
          >
            <CloudDownloadIcon />
          </IconButton>
        ) : null}
        {task.variation === NotificationVariation.COMPOUND ? (
          <IconButton onClick={handleClick} size="small">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        ) : null}
      </ListItem>
      {task.variation === NotificationVariation.COMPOUND ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {task.messages.map((message, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <TypeIcon type={message.type} />
                </ListItemIcon>
                <ListItemText primary={message.message} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : null}

      <Divider />
    </React.Fragment>
  );
};

const initialState = {
  minimize: false,
  lastMessageCount: 0,
};

export default function SidePanel({ sidePanelInfo }) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  const hasItems = sidePanelInfo.length !== 0;
  const newItems = sidePanelInfo.length > state.lastMessageCount;
  const hasErrors = sidePanelInfo.some((task) =>
    task.messages.some((message) => message.type === "error")
  );

  const inProgress = sidePanelInfo.some((task) =>
    task.messages.every((message) => message.progress !== 100)
  );

  const handleClickMini = () =>
    setState((prevState) => ({
      ...prevState,
      minimize: !prevState.minimize,
      lastMessageCount: sidePanelInfo.length,
    }));

  return (
    <React.Fragment>
      <Grow in={hasItems && state.minimize} mountOnEnter unmountOnExit>
        <Paper className={classes.sidePanel}>
          <Grid container>
            <Grid item sm={12}>
              <ListItem>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText>
                  {sidePanelInfo.length}{" "}
                  {sidePanelInfo.length > 1 ? " Tasks" : " Task"}
                </ListItemText>
                <IconButton onClick={handleClickMini} size="large">
                  {!state.minimize ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item sm={12} className={classes.flexScroll}>
              {sidePanelInfo.map((task, index) => (
                <Task task={task} key={index} />
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Grow>
      <Grow in={hasItems && !state.minimize} mountOnEnter unmountOnExit>
        <IconButton
          className={classes.taskButton}
          component={Paper}
          onClick={handleClickMini}
          size="large">
          {newItems ? (
            hasErrors ? (
              <ErrorBadge badgeContent={sidePanelInfo.length}>
                <AssignmentIcon />
              </ErrorBadge>
            ) : inProgress ? (
              <NormalBadge badgeContent={sidePanelInfo.length}>
                <AssignmentIcon />
              </NormalBadge>
            ) : (
              <SuccessBadge badgeContent={sidePanelInfo.length}>
                <AssignmentIcon />
              </SuccessBadge>
            )
          ) : (
            <AssignmentIcon />
          )}
          {inProgress ? (
            <CircularProgress size={50} className={classes.fabProgress} />
          ) : null}
        </IconButton>
      </Grow>
    </React.Fragment>
  );
}
