import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { toolGroupsEndpoint } from "../../Services/apiEndpoint";
import Form from "./Form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  name: ""
};

export default function Create({ open, setOpen, onCreate }) {

  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(initialEntry);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  React.useEffect(() => {
    setSubmitted(false);
    setErrors([]);
    setInternalEntry(initialEntry);
    setLoading(false);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Creating Tool Group", loading: true },
    });
    setSubmitted(true);
    setLoading(true);
    const entryDto = { ...internalEntry };

    api.post(toolGroupsEndpoint, entryDto)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Tool Group Created", type: "success" },
        });
        setOpen(false);
        setLoading(false);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">{"New Tool Group"}</DialogTitle>
      <DialogContent>
        <Form
          entry={internalEntry}
          errors={errors}
          setEntry={setInternalEntry}
          loading={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          autoFocus
          disabled={loading || saveDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
