import Grid from "@mui/material/Grid";
import React, { useContext } from "react";
import _ from "lodash";
import makeStyles from '@mui/styles/makeStyles';
import { useState } from "react";
import { useEffect } from "react";
import { Divider } from "@mui/material";
import FileUploader from "../../General/FileUploader";
import FileReaderConfiguration from "./FileReaderConfiguration";
import PreviewParser from "./PreviewParser";
import { HomeAppContext } from "../../Home";
import { appliesToEndpoint, toolsDropdownEndpoint, uploadsEndpoint, xtrfClientsEndpoint } from "../../../Services/apiEndpoint";
import CustomAutocomplete from "../../General/CustomAutocomplete";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";
import { useCallback } from "react";
import EquivalencyConfiguration from "./EquivalencyConfiguration";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
  },
  dropdowns: {
    marginTop: "16px"
  }
}));

export default function UploadForm({
  entry = null,
  errors = [],
  setEntry,
  loading,
  submitted,
  setSaveDisabled,
  onSetDirty = null,
}) {
  const classes = useStyles();

  const { homeDispatch } = useContext(HomeAppContext);
  const [files, setFiles] = useState([]);
  const [internalLoading, setInternalLoading] = useState(false);
  const [fileParsed, setFileParsed] = useState(null);
  const [disabledTools, setDisabledTools] = useState([]);

  useEffect(() => {
    setEntry((prevEntry) => ({ ...prevEntry, files: files }));
  }, [files, setEntry]);

  useEffect(() => {
    setDisabledTools(entry.equivalencyConfiguration.mappings.map(mapping => mapping.tool));
  }, [entry]);

  const onFileReaderConfigurationValueChange = useCallback((value, property) => {
    setEntry((prevEntry) => ({
      ...prevEntry,
      fileReaderConfiguration: {
        ...prevEntry.fileReaderConfiguration,
        [property]: value || undefined,
      },
    }));
  }, [setEntry]);

  const resetReaderConfiguration = useCallback(() => {
    onFileReaderConfigurationValueChange({}, "identifierColumn");
    onFileReaderConfigurationValueChange({}, "nameColumn");
    onFileReaderConfigurationValueChange({}, "shortNameColumn");
    onFileReaderConfigurationValueChange({}, "codeColumn");
    onFileReaderConfigurationValueChange({}, "iso639Dash1Column");
    onFileReaderConfigurationValueChange({}, "iso639Dash2Column");
    onFileReaderConfigurationValueChange({}, "externalIdColumn");
    onFileReaderConfigurationValueChange({}, "writingDirectionColumn");
    onFileReaderConfigurationValueChange({}, "writingSystemColumn");
  }, [onFileReaderConfigurationValueChange]);

  const firstFile = entry.files[0];
  useEffect(() => {
    if (
      firstFile !== undefined &&
      firstFile.uploading === false &&
      firstFile.error === false &&
      firstFile.remove === false &&
      firstFile.progress === 100
    ) {
      setInternalLoading(true);
      api
        .get(`${uploadsEndpoint}/${firstFile.id}/preview`)
        .then((r) => {
          setFileParsed(r.data);
          setInternalLoading(false);
          setSaveDisabled(false);
        })
        .catch((error) => {
          setFileParsed(null);
          setInternalLoading(false);
          resetReaderConfiguration();
          setSaveDisabled(true);
          errorManagement.formErrors(error, homeDispatch, null, {
            400: "Could not generate preview",
          });
        });
    } else if (firstFile === undefined) {
      setFileParsed(null);
      setInternalLoading(false);
      resetReaderConfiguration();
      setSaveDisabled(true);
    }
  }, [firstFile, homeDispatch, resetReaderConfiguration, setSaveDisabled]);

  const onValueChange = (value, property) => {
    setEntry({ ...entry, [property]: value });
    if (onSetDirty !== null) onSetDirty(true);
  };

  const equivalencyConfigChange = (value, property) => {
    setEntry((prevEntry) => ({
      ...prevEntry,
      equivalencyConfiguration: {
        ...prevEntry.equivalencyConfiguration,
        [property]: value,
      }
    }));
  }

  return entry === null ? null : (
    <React.Fragment>
      <Grid container className={classes.paper} spacing={2}>
        <Grid item sm={12} >
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <CustomAutocomplete
                    value={entry.tool}
                    setValue={(value) => onValueChange(value, "tool")}
                    related={null}
                    url={toolsDropdownEndpoint}
                    liveOptions={false}
                    label={"Tool"}
                    placeholder={"Select a tool"}
                    noOptionsText={"No tool found"}
                    loadingText={"Searching"}
                    error={_.isArray(errors.Tool)}
                    helperText={_.isArray(errors.Tool) && errors.Tool.join()}
                    disableClearable={true}
                    required={true}
                    onLoading={setSaveDisabled}
                    optionsDisabled={disabledTools}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CustomAutocomplete
                    value={entry.appliesTo}
                    setValue={(value) => onValueChange(value, "appliesTo")}
                    related={null}
                    url={appliesToEndpoint}
                    liveOptions={false}
                    label={"Applies to"}
                    placeholder={"Select a target"}
                    noOptionsText={"No target found"}
                    loadingText={"Searching"}
                    error={_.isArray(errors.AppliesTo)}
                    helperText={
                      _.isArray(errors.AppliesTo) && errors.AppliesTo.join()
                    }
                    disableClearable={true}
                    required={true}
                    onLoading={setSaveDisabled}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CustomAutocomplete
                    value={entry.xtrfClient}
                    setValue={(value) => onValueChange(value, "xtrfClient")}
                    related={null}
                    url={xtrfClientsEndpoint}
                    label={"XTRF Client"}
                    emptyValueText={"Global Mapping, no XTRF client specified"}
                    placeholder={"Select XTRF client"}
                    noOptionsText={"No XTRF client found"}
                    loadingText={"Searching XTRF"}
                    error={_.isArray(errors.XtrfClient)}
                    helperText={
                      _.isArray(errors.XtrfClient) && errors.XtrfClient.join()
                    }
                    onLoading={setSaveDisabled}
                  />
                </Grid>
                {false && (
                  <Grid item sm={6}>
                    <CustomAutocomplete
                      value={entry.tmsClient}
                      setValue={(value) => onValueChange(value, "tmsClient")}
                      related={null}
                      url={xtrfClientsEndpoint}
                      label={"TMS Client"}
                      emptyValueText={"Global Mapping, no TMS client specified"}
                      placeholder={"Select TMS client"}
                      noOptionsText={"No TMS client found"}
                      loadingText={"Searching TMS"}
                      error={_.isArray(errors.TmsClient)}
                      helperText={
                        _.isArray(errors.TmsClient) && errors.TmsClient.join()
                      }
                      onLoading={setSaveDisabled}
                    />
                  </Grid>
                )}
                {false && (
                  <Grid item sm={6}>
                    <CustomAutocomplete
                      value={entry.salesforceClient}
                      setValue={(value) => onValueChange(value, "salesforceClient")}
                      related={null}
                      url={xtrfClientsEndpoint}
                      label={"Salesforce Client"}
                      emptyValueText={"Global Mapping, no Salesforce client specified"}
                      placeholder={"Select Salesforce client"}
                      noOptionsText={"No Salesforce client found"}
                      loadingText={"Searching Salesforce"}
                      error={_.isArray(errors.SalesforceClient)}
                      helperText={
                        _.isArray(errors.SalesforceClient) && errors.SalesforceClient.join()
                      }
                      onLoading={setSaveDisabled}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <FileUploader
                uploadUrl={uploadsEndpoint}
                acceptedFileExtensions=".csv, .tsv, .xlsx"
                acceptedFileExtensionsMessage=".csv,.tsv,.xlsx"
                maxSize={20971520}
                onUploading={setSaveDisabled}
                onFileUploaded={setFiles}
                disabled={loading || internalLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} >
          <Divider />
        </Grid>
        <Grid item sm={12}>
          <FileReaderConfiguration
            entry={entry.fileReaderConfiguration}
            onChange={onFileReaderConfigurationValueChange}
            fileParsed={fileParsed}
            loading={loading || internalLoading}
            errors={errors}
          />
        </Grid>
        <Grid item sm={12} >
          <Divider />
        </Grid>
        <Grid item sm={12}>
          <EquivalencyConfiguration
            entry={entry}
            onChange={equivalencyConfigChange}
            fileParsed={fileParsed}
          />
        </Grid>
        <Grid item sm={12} >
          <Divider />
        </Grid>
        <Grid item sm={12}>
          <PreviewParser file={fileParsed} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
