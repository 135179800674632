import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Slide, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { forwardRef } from "react";
import Progress from "./Progress";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
  },
  floatRight: {
    float: "right"
  },
  detail: {
    marginLeft: "20px"
  }
}));

export default function ImportSummary({ open, setOpen, summary, setUpload, onUpload, processNotification }) {
  const classes = useStyles();

  const [alertSeverity, setAlertSeverity] = useState(undefined);
  const [hasDownload, setHasDownload] = useState(false);

  useEffect(() => {
    if (!summary) {
      return;
    }

    if (summary.found > 0 && summary.imported === 0) {
      setAlertSeverity('error');
    }
    else if (summary.found > 0 && summary.found === summary.imported) {
      setAlertSeverity('success');
    }
    else if (summary.found > 0 && summary.imported < summary.found) {
      setAlertSeverity('warning');
    }
    else {
      setAlertSeverity('error');
    }
    if (summary.hasError) {
      setAlertSeverity('error');
    }
    if (summary.downloadUrl) {
      setHasDownload(true);
    }
  }, [summary]);

  useEffect(() => {
    if (open) {
      setUpload(false);
      onUpload(true);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    window.location = summary.downloadUrl;
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {
          (processNotification && !summary) ? "Processing Upload" : "Upload Summary"
        }
      </DialogTitle>
      <DialogContent>
        {processNotification && !summary &&
          <Progress
            notification={processNotification}
          />
        }
        {summary &&
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Alert severity={alertSeverity}>{'Uploaded file(s) has been imported. Please find the summary below'}</Alert>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item sm={2}>
                  <Box className={classes.header}>Records Found:</Box>
                  <Box className={classes.detail}>{summary.found}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>With Errors:</Box>
                  <Box className={classes.detail}>{summary.errors}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>Without Errors:</Box>
                  <Box className={classes.detail}>{summary.imported}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>Duplicates:</Box>
                  <Box className={classes.detail}>
                    {summary.duplicates}
                    {summary.duplicateInstances > 0 &&
                      <Typography variant='span'> ({summary.duplicateInstances})</Typography>
                    }
                  </Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>With No Equivalencies:</Box>
                  <Box className={classes.detail}>{summary.noEquivalencies}</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>Skipped:</Box>
                  <Box className={classes.detail}>{summary.skipped}</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              <Alert severity="info">Summary of the uploaded files below</Alert>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item sm={5}>
                  <Box className={classes.header}>File Name</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>Found</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>OK</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>With Errors</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>Duplicates</Box>
                </Grid>
                <Grid item sm={1}>
                  <Box className={classes.header}>Skipped</Box>
                </Grid>
                <Grid item sm={2}>
                  <Box className={classes.header}>No Equivalencies</Box>
                </Grid>
              </Grid>
            </Grid>
            {(summary.summary || []).map((fileSummary, index) => (
              <Grid item sm={12} key={index}>
                <Grid container spacing={2}>
                  <Grid item sm={5}>
                    <Box>{fileSummary.fileName}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.languagesFound || '-'}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.languagesSaved || '-'}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.errorImports || '-'}</Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>
                      {fileSummary.duplicates || '-'}
                      {fileSummary.duplicateInstances > 0 &&
                        <Typography variant='span'> ({fileSummary.duplicateInstances})</Typography>
                      }
                    </Box>
                  </Grid>
                  <Grid item sm={1}>
                    <Box>{fileSummary.skipped || '-'}</Box>
                  </Grid>
                  <Grid item sm={2}>
                    <Box>{fileSummary.noEquivalencies || '-'}</Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item sm={12}>
              {hasDownload &&
                <Button
                  onClick={handleDownload}
                  color="primary"
                  className={classes.floatRight}
                >
                  Download Detail Report
                </Button>}
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        {summary &&
          <Button onClick={handleClose}>
            Close
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}