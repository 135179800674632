export const userStore = {
  _user_profile: null,
  _token: "",
  _refresh_token: "",
  _sidebarMenuOpen: true,
  _editorRecodingSessionAccepted: false,
  _editorHelpHidden: false,
  isLogged() {
    return this.token;
  },
  clearProfile() {
    this.user_profile = null;
    this.token = "";
    this.refresh_token = "";
    localStorage.clear();
  },
  profileLoaded() {
    return this.hasOwnProperty("user_profile") && this.user_profile !== null;
  },
  timezoneLoaded() {
    return (
      this.profileLoaded() &&
      this.user_profile.hasOwnProperty("timezone") &&
      this.user_profile.timezone !== "" &&
      this.user_profile.timezone !== null
    );
  },
  set sidebarMenuOpen(val) {
    this._sidebarMenuOpen = val;
    localStorage.setItem("SidebarMenuOpen", val);
  },
  get sidebarMenuOpen() {
    return localStorage.getItem("SidebarMenuOpen") !== null
      ? localStorage.getItem("SidebarMenuOpen") === "true"
      : this._sidebarMenuOpen;
  },
  set editorRecodingSessionAccepted(val) {
    this._editorRecodingSessionAccepted = val;
    localStorage.setItem("EditorRecodingSessionAccepted", val);
  },
  get editorRecodingSessionAccepted() {
    return localStorage.getItem("EditorRecodingSessionAccepted") !== null
      ? localStorage.getItem("EditorRecodingSessionAccepted") === "true"
      : this._editorRecodingSessionAccepted;
  },
  set editorHelpHidden(val) {
    this._editorHelpHidden = val;
    localStorage.setItem("EditorHelpHidden", val);
  },
  get editorHelpHidden() {
    return localStorage.getItem("EditorHelpHidden") !== null
      ? localStorage.getItem("EditorHelpHidden") === "true"
      : this._editorHelpHidden;
  },
  get profile() {
    if (this.profileLoaded()) {
      return this.user_profile;
    } else {
      return false;
    }
  },
  get roles() {
    if (this.profileLoaded() && this.user_profile.hasOwnProperty("roles")) {
      return this.user_profile.roles;
    } else {
      return ["ROLE_USER"];
    }
  },
  get timezone() {
    if (this.profileLoaded() && this.user_profile.hasOwnProperty("timezone")) {
      return this.user_profile.timezone !== null
        ? this.user_profile.timezone
        : "UTC";
    } else {
      return "UTC";
    }
  },
  get dateFormat() {
    if (
      this.profileLoaded() &&
      this.user_profile.hasOwnProperty("date_format")
    ) {
      return this.user_profile.date_format;
    } else {
      return "YYYY-MM-DD HH:mm";
    }
  },
  get dateFormatMoment() {
    if (
      this.profileLoaded() &&
      this.user_profile.hasOwnProperty("date_format")
    ) {
      return this.user_profile.date_format.match(/^(\S+)\s(.*)/).slice(1)[0];
    } else {
      return "YYYY-MM-DD";
    }
  },
  get timeAMPM() {
    if (
      this.profileLoaded() &&
      this.user_profile.hasOwnProperty("date_format")
    ) {
      return this.user_profile.date_format.indexOf("A") !== -1;
    } else {
      return false;
    }
  },
  get timeFormatMoment() {
    if (
      this.profileLoaded() &&
      this.user_profile.hasOwnProperty("date_format")
    ) {
      return this.user_profile.date_format.match(/^(\S+)\s(.*)/).slice(1)[1];
    } else {
      return "HH:mm";
    }
  },
  get dateFormatFns() {
    //https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
    if (
      this.profileLoaded() &&
      this.user_profile.hasOwnProperty("date_format")
    ) {
      return this.user_profile.date_format
        .replace("YYYY", "yyyy")
        .replace("DD", "dd")
        .match(/^(\S+)\s(.*)/)
        .slice(1)[0];
    } else {
      return "yyyy-MM-dd";
    }
  },
  get profilePicture() {
    if (this.profileLoaded()) {
      return (
        process.env.BACKEND_URL +
        process.env.BACKEND_USERS_AVATAR_PATH +
        this.user_profile.picture
      );
    } else {
      return "";
    }
  },
  set token(str) {
    this._token = str;
    localStorage.setItem("ApiToken", str);
  },
  get token() {
    return this._token || localStorage.getItem("ApiToken");
  },
  set refresh_token(str) {
    this._refresh_token = str;
    localStorage.setItem("RefreshApiToken", str);
  },
  get refresh_token() {
    return this._refresh_token || localStorage.getItem("RefreshApiToken");
  },
  set user_profile(data) {
    this._user_profile = JSON.stringify(data);
    localStorage.setItem("UserProfile", JSON.stringify(data));
  },
  get user_profile() {
    let user_profile = this._user_profile;
    if (user_profile === null) {
      user_profile = localStorage.getItem("UserProfile");
    }
    return JSON.parse(user_profile);
  },
};
