import { Grid } from "@mui/material";
import React from "react";

const EquivalencyEntry = ({ entry }) => {
  return (
    <Grid item sm={12}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item sm={2}>{entry.tool.text}</Grid>
        <Grid item sm={2}>{entry.appliesTo.text}</Grid>
        <Grid item sm={2}>{entry.name}</Grid>
        <Grid item sm={2}>{entry.identifier}</Grid>
        <Grid item sm={1}>{entry.code}</Grid>
        <Grid item sm={1}>{entry.iso639Dash1}</Grid>
        <Grid item sm={1}>{entry.iso639Dash2}</Grid>
      </Grid>
    </Grid>
  );
};

export default EquivalencyEntry;
