import { Badge, Checkbox, Divider } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  divider: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  mainHeader: {
    paddingTop: "5px!important",
  },
  header: {
    fontWeight: "bold",
  },
  sortable: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.main,
    },
  },
  headers: {
    minHeight: 34,
  },
}));

const SortBadge = withStyles((theme) => ({
  badge: {
    transform: "scale(0.8)",
    right: 4,
    top: -12,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "white",
    color: theme.palette.primary.main,
    padding: "0 4px",
  },
  root: {
    height: 20,
  },
}))(Badge);

const Icon = (sort, order) => {
  return (
    <React.Fragment>
      <SortBadge badgeContent={order} color="primary">
        {sort.value === "" ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </SortBadge>
    </React.Fragment>
  );
};

const RenderSort = ({ column, sortValues }) => {
  const sort = sortValues.find((x) => x.name === column.name);
  const order =
    sortValues.length === 1
      ? 0
      : sortValues.findIndex((x) => x.name === column.name) + 1;
  return sort ? Icon(sort, order) : null;
};

const Headers = ({
  columns,
  sortValues,
  setSortValues,
  multiSelect = false,
  multiSelectCheck,
  handleSelectToggle,
}) => {
  const classes = useStyles();

  const setSort = (event, column) => {
    const sort = sortValues.find((x) => x.name === column.name);
    if (sort) {
      if (sort.value === "") {
        setSortValues(
          sortValues.map((x) =>
            x.name === column.name ? { ...x, value: "-" } : x
          )
        );
      } else {
        setSortValues(sortValues.filter((x) => x.name !== column.name));
      }
    } else {
      setSortValues([...sortValues, { name: column.name, value: "" }]);
    }
  };

  const isString = (x) => {
    return Object.prototype.toString.call(x) === "[object String]";
  };

  const renderHeaders = () => {
    return (
      <Grid container alignItems={"center"} spacing={2}>
        {columns.map((column, index) => (
          <Grid
            item
            {...(column.size === "auto"
              ? {
                  style: {
                    flex: 1,
                    textAlign: column.align || "left",
                    paddingBottom: 0,
                  },
                }
              : isString(column.size) && column.size.includes("%")
              ? {
                  style: {
                    textAlign: column.align || "left",
                    paddingBottom: 0,
                    flexGrow: 0,
                    maxWidth: column.size,
                    flexBasis: column.size,
                  },
                }
              : {
                  sm: column.size,
                  style: {
                    textAlign: column.align || "left",
                    paddingBottom: 0,
                  },
                })}
            key={index}
          >
            <Box
              component="div"
              display="inline"
              className={
                column.sortable
                  ? clsx(classes.header, classes.sortable)
                  : classes.header
              }
              onClick={
                column.sortable ? (event) => setSort(event, column) : null
              }
            >
              {column.description}
              {column.sortable ? (
                <RenderSort column={column} sortValues={sortValues} />
              ) : null}
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      className={classes.mainHeader}
    >
      {multiSelect ? (
        <Grid item sm={12}>
          <Grid container alignItems={"center"}>
            <Grid item>
              <Checkbox
                checked={multiSelectCheck}
                onClick={handleSelectToggle}
              />
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {renderHeaders()}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item sm={12}>
          {renderHeaders()}
        </Grid>
      )}

      <Grid className={multiSelect ? classes.divider : null} item sm={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Headers;
