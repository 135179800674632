import FindInPageIcon from "@mui/icons-material/FindInPage";
import Grid from "@mui/material/Grid";
import {IconButton} from "@mui/material";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 200,
        opacity: "30%",
    },
    message: {
        color: theme.palette.secondary.main,
    },
}));

export default function NoEntriesFound({
                                           message,
                                           compact = false,
                                           onRefresh = null,
                                       }) {
    const classes = useStyles();

    return compact ? (
        <Grid container alignItems={"center"} justifyContent={"center"} spacing={1}>
            <Grid item>
                <FindInPageIcon className={classes.icon} style={{fontSize: 40}}/>
            </Grid>
            <Grid item>
                <Typography className={classes.message}>{message}</Typography>
            </Grid>
            {onRefresh ? (
                <IconButton
                    onClick={onRefresh}
                    aria-label="refresh"
                    color="secondary"
                    title="Refresh"
                    size="large">
                    <RefreshIcon/>
                </IconButton>
            ) : null}
        </Grid>
    ) : (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
        >
            <Grid item>
                <FindInPageIcon className={classes.icon}/>
            </Grid>
            <Grid item>
                <Typography className={classes.message}>{message}</Typography>
            </Grid>
            {onRefresh ? (
                <IconButton
                    onClick={onRefresh}
                    aria-label="refresh"
                    color="secondary"
                    title="Refresh"
                    size="large">
                    <RefreshIcon/>
                </IconButton>
            ) : null}
        </Grid>
    );
}
