import { CircularProgress, Container, Fade, TextField, } from "@mui/material";
import React, { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link, useHistory } from "react-router-dom";
import PasswordField from "../General/PasswordField";
import Typography from "@mui/material/Typography";
import api from "../../Services/api";
import makeStyles from '@mui/styles/makeStyles';
import queryString from "query-string";
import { passwordResetEndpoint, passwordResetValidateEndpoint } from "../../Services/apiEndpoint";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 500,
    minHeight: 300,
  },
}));

export default function UserResetPassword() {
  const classes = useStyles();
  const history = useHistory();

  const [userProfile, setUserProfile] = useState({
    fullname: "",
    picture: "",
    email: "",
  });
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState("initial");

  const [passwordValid, setPasswordValid] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  const validPassword =
    passwordValid &&
    newPasswordRepeat !== "" &&
    newPassword === newPasswordRepeat;

  const showPasswordError =
    (newPassword !== "" || newPasswordRepeat !== "") &&
    newPassword !== newPasswordRepeat;

  React.useEffect(() => {
    const UrlQueryStrings = history.location.search;
    const params = {
      jwt: queryString.parse(UrlQueryStrings).token,
    };
    api
      .post(passwordResetValidateEndpoint, params)
      .then(function (response) {
        setUserProfile({
          fullname: response.data.fullName,
          picture: response.data.picture,
          email: response.data.email,
        });
        setState("initial");

        setLoading(false);
      })
      .catch(function (error) {
        setState("expired");
        setLoading(false);
      });
  }, [history.location.search]);

  const onChangePasswordButton = () => {
    if (validPassword) {
      const UrlQueryStrings = history.location.search;
      const params = {
        jwt: queryString.parse(UrlQueryStrings).token,
        newPassword: newPassword,
      };
      api
        .post(passwordResetEndpoint, params)
        .then(function (response) {
          setState("success");

          setLoading(false);
        })
        .catch(function (error) {
          setState("expired");
          setLoading(false);
        });
    }
  };

  const onNewPasswordChange = (value) => setNewPassword(value);

  const onNewPasswordRepeatChange = (event) =>
    setNewPasswordRepeat(event.target.value);

  const RenderInvalidToken = () => (
    <Container>
      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item sm={12}>
          <Typography variant={"h5"} gutterBottom>
            Token expired
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography> Sorry, but your reset token has expired.</Typography>
          <Typography>
            Please request a password recovery again at the login page
          </Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/login"
            color="primary"
            variant="contained"
          >
            Go to login page
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  const RenderPasswordChangeSuccess = () => (
    <Container>
      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item sm={12}>
          <Typography variant={"h5"} gutterBottom>
            Success
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography>Your password has been changed successfully.</Typography>
          <Typography>
            Please, go to the login page and try to log in again.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/login"
            color="primary"
            variant="contained"
          >
            Go to login page
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  const RenderPasswordChange = (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item sm={12}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item sm={12}>
            <Avatar alt={userProfile.fullName} src={userProfile.picture} />
          </Grid>
          <Grid item sm={12}>
            {userProfile.fullname}
          </Grid>
          <Grid item sm={12}>
            {userProfile.email}
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <input type="hidden" name="email" value={userProfile.email} />
        <PasswordField
          value={newPassword}
          setValue={onNewPasswordChange}
          showError={true}
          label={"New Password"}
          setValid={setPasswordValid}
        />
      </Grid>

      <Grid item sm={12}>
        <TextField
          margin="normal"
          required
          autoComplete="new-password-repeat"
          fullWidth
          error={newPasswordRepeat !== "" && !validPassword}
          helperText={showPasswordError ? "The passwords are not equal" : ""}
          type="password"
          label="New Password Repeat"
          value={newPasswordRepeat}
          onChange={onNewPasswordRepeatChange}
        />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          disabled={!validPassword}
          onClick={onChangePasswordButton}
        >
          Change Password
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <Container component="main" className={classes.container}>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}
        >
          <Grid item>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </Grid>
        </Grid>
      ) : (
        {
          initial: RenderPasswordChange,
          expired: <RenderInvalidToken />,
          success: <RenderPasswordChangeSuccess />,
        }[state]
      )}
    </Container>
  );
}
