import makeStyles from '@mui/styles/makeStyles';
import { red} from '@mui/material/colors';

export const stylesCalendarStatusSmall = makeStyles((theme) => ({
  dayWrapper: {},
  day: {
    width: 36,
    height: 36,
    fontSize: 12,
    margin: "0 1px",
    color: "inherit",
  },
  dayWeekEnd: {
    color: red[500],
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  nonCurrentMonthWeekEnd: {
    color: red[200],
  },
  highlightNonCurrentMonthDay: {
    color: "white",
  },
  highlight: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  currentDay: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

const stylesCalendarStatus = makeStyles((theme) => ({
  dayWrapper: {},
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.body2.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  dayWeekEnd: {
    color: red[500],
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  nonCurrentMonthWeekEnd: {
    color: red[200],
  },
  highlightNonCurrentMonthDay: {
    color: "white",
  },
  highlight: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  currentDay: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default stylesCalendarStatus;
