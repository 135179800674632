import React, { } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Grid, Typography } from "@mui/material";
import CopyValue from "../../General/CopyValue";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserCredential({ open, setOpen, createdUser }) {

  React.useEffect(() => {
    if (open) {

    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">{"Service Account Credentials"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Typography variant="span">User ID:</Typography>
          </Grid>
          <Grid item sm={9}>
            <CopyValue value={createdUser.email} />
          </Grid>
          <Grid item sm={3}>
            <Typography variant="span">Password:</Typography>
          </Grid>
          <Grid item sm={9}>
            <CopyValue value={createdUser.password} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
