import { Alert, Grid, Typography } from "@mui/material";

import React from "react";
import CustomAutocomplete from "../../General/CustomAutocomplete";

const FileReaderConfiguration = ({
  entry = null,
  onChange,
  fileParsed = null,
  loading = false,
  errors = [],
}) => {

  const columnOptions = (fileParsed?.originalFileColumnNames || []).map((x) => { return { id: x, text: x } });
  const gridWidth = 3;

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h6">Reader Configuration</Typography>
      </Grid>
      {
        fileParsed && fileParsed.warnings &&
        <React.Fragment>
          {fileParsed.warnings.map((item, index) => (
            <Grid item sm={4} key={index}>
              <Alert severity="warning">{item}</Alert>
            </Grid>
          ))}
        </React.Fragment>
      }
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.identifierColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "identifierColumn")}
          related={null}
          url={null}
          externalOptions={columnOptions}
          label={"Identifier Column"}
          placeholder={"Identifier Column"}
          noOptionsText={"No Identifier column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["FileReaderConfiguration.IdentifierColumn"]
          )}
          helperText={
            Array.isArray(errors["FileReaderConfiguration.IdentifierColumn"]) &&
            errors["FileReaderConfiguration.IdentifierColumn"].join()
          }
          disabled={loading || fileParsed === null}
          required={true}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.nameColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "nameColumn")}
          related={null}
          url={null}
          externalOptions={columnOptions}
          label={"Display Name Column"}
          placeholder={"Display Name Column"}
          noOptionsText={"No Display Name column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.nameColumn"]
          )}
          helperText={
            Array.isArray(errors["fileReaderConfiguration.nameColumn"]) &&
            errors["fileReaderConfiguration.nameColumn"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.shortNameColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "shortNameColumn")}
          related={null}
          externalOptions={columnOptions}
          label={"Short Name Column"}
          placeholder={"Short Name Column"}
          noOptionsText={"No Short Name column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.shortNameColumn"]
          )}
          helperText={
            Array.isArray(errors["fileReaderConfiguration.shortNameColumn"]) &&
            errors["fileReaderConfiguration.shortNameColumn"].join()
          }
          required={false}
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.codeColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "codeColumn")}
          related={null}
          externalOptions={columnOptions}
          label={"Code Column"}
          placeholder={"Code Column"}
          noOptionsText={"No Code column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.codeColumn"]
          )}
          helperText={
            Array.isArray(
              errors["fileReaderConfiguration.codeColumn"]
            ) && errors["fileReaderConfiguration.codeColumn"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.iso639Dash1Column}
          liveOptions={false}
          setValue={(value) => onChange(value, "iso639Dash1Column")}
          related={null}
          externalOptions={columnOptions}
          label={"ISO 639-1 Column"}
          placeholder={"ISO 639-1 Column"}
          noOptionsText={"No ISO 639-1 column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.iso639Dash1Column"]
          )}
          helperText={
            Array.isArray(
              errors["fileReaderConfiguration.iso639Dash1Column"]
            ) && errors["fileReaderConfiguration.iso639Dash1Column"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.iso639Dash2Column}
          liveOptions={false}
          setValue={(value) => onChange(value, "iso639Dash2Column")}
          related={null}
          externalOptions={columnOptions}
          label={"ISO 639-2 Column"}
          placeholder={"ISO 639-2 Column"}
          noOptionsText={"No ISO 639-2 column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.iso639Dash2Column"]
          )}
          helperText={
            Array.isArray(
              errors["fileReaderConfiguration.iso639Dash2Column"]
            ) && errors["fileReaderConfiguration.iso639Dash2Column"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.externalIdColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "externalIdColumn")}
          related={null}
          externalOptions={columnOptions}
          label={"External Id Column"}
          placeholder={"External Id Column"}
          noOptionsText={"No External Id column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.externalIdColumn"]
          )}
          helperText={
            Array.isArray(errors["fileReaderConfiguration.externalIdColumn"]) &&
            errors["fileReaderConfiguration.externalIdColumn"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.writingDirectionColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "writingDirectionColumn")}
          related={null}
          externalOptions={columnOptions}
          label={"Writing Direction Column"}
          placeholder={"Writing Direction Column"}
          noOptionsText={"No Writing Direction column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.writingDirectionColumn"]
          )}
          helperText={
            Array.isArray(errors["fileReaderConfiguration.writingDirectionColumn"]) &&
            errors["fileReaderConfiguration.writingDirectionColumn"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
      <Grid item sm={gridWidth}>
        <CustomAutocomplete
          value={entry.writingSystemColumn}
          liveOptions={false}
          setValue={(value) => onChange(value, "writingSystemColumn")}
          related={null}
          externalOptions={columnOptions}
          label={"Writing System Column"}
          placeholder={"Writing System Column"}
          noOptionsText={"No Writing System column found"}
          loadingText={"Searching"}
          error={Array.isArray(
            errors["fileReaderConfiguration.writingSystemColumn"]
          )}
          helperText={
            Array.isArray(errors["fileReaderConfiguration.writingSystemColumn"]) &&
            errors["fileReaderConfiguration.writingSystemColumn"].join()
          }
          disabled={loading || fileParsed === null}
        />
      </Grid>
    </Grid>
  );
};

export default FileReaderConfiguration;
