import {
  Bell,
  CardAccountDetailsOutline,
  CardBulletedOffOutline,
  CardTextOutline,
  ContentCopy,
  ContentDuplicate,
  ContentPaste,
  DatabaseSearch,
  DesktopClassic,
  EmailEdit,
  EmailArrowRight,
  FileExportOutline,
  FormatListGroup,
  FormatTextVariant,
  GoogleTranslate,
  MicrosoftExcel,
  OpenInNew,
  PostOutline,
  PoundBoxOutline,
  Spellcheck,
  TestTube,
  Transcribe,
  Translate,
  LightbulbGroup,
} from "mdi-material-ui";

import PlayIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import MailIcon from "@mui/icons-material/Mail";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PeopleIcon from "@mui/icons-material/People";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import TranslateIcon from "@mui/icons-material/Translate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import makeStyles from "@mui/styles/makeStyles";
import { red } from "@mui/material/colors";
import clsx from "clsx";
import { Lightbulb, Settings, SettingsSuggest, VpnKey } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  dangerIcon: { color: red[400] },
  spacerIcon: { display: "inline-block", width: 30, height: 10 },
}));

const GeSpacerIconStyled = (props) => {
  const classes = useStyles();
  return <div className={classes.spacerIcon} />;
};
export const GeSpacerIcon = GeSpacerIconStyled;

const TpRemoveIconStyled = (props) => {
  const classes = useStyles();
  return (
    <RemoveIcon
      {...{
        ...props,
        className: props.className
          ? clsx(props.className, classes.dangerIcon)
          : classes.dangerIcon,
      }}
    />
  );
};
export const TpRemoveIcon = TpRemoveIconStyled;

export const GeSettings = SettingsIcon;
export const GeSettingsGeneral = DesktopClassic;
export const GeEmailIcon = EmailIcon;
export const GeTestsIcon = TestTube;
export const GeNotificationIcon = Bell;
export const GeUsersIcon = PeopleIcon;
export const GeCheckTrue = CheckCircleOutlineIcon;
export const GeCheckFalse = ClearIcon;
export const GeDashboardIcon = DashboardIcon;
export const GeReports = AssessmentIcon;
export const GeExportExcel = MicrosoftExcel;
export const GeExport = FileExportOutline;
export const GeError = ErrorIcon;
export const GeInfo = InfoIcon;
export const GeClone = ContentDuplicate;
export const GeValueNotAvailable = CardBulletedOffOutline;
export const GeAutomatic = DatabaseSearch;
export const GeMailLogs = MailIcon;
export const GeAdHocNotification = EmailEdit;
export const GeBack = ArrowBackIcon;
export const GeProjectIcon = AccountTreeIcon;
export const GeLanguageIcon = TranslateIcon;
export const GeSplitIcon = CallSplitIcon;
export const GeMergeIcon = CallMergeIcon;
export const GeModuleTokenIcon = ListAltIcon;
export const GeGeneralTagsIcon = LocalOfferIcon;
export const GeGenderTagsIcon = LoyaltyIcon;
export const GeCopyValuesIcon = FileCopyIcon;
export const GeVendorIcon = CardAccountDetailsOutline;
export const GeLogIcon = PostOutline;
export const GeRefreshIcon = RefreshIcon;
export const GePasteIcon = ContentPaste;
export const GeCopyIcon = ContentCopy;
export const GeMtEvaluateIcon = GoogleTranslate;
export const GeExternalLinkIcon = LaunchIcon;
export const GeTrueIcon = CheckCircleIcon;
export const GeFalseIcon = NotInterestedIcon;

export const GeNewIcon = AddBoxIcon;
export const GeSaveIcon = SaveIcon;
export const GeViewIcon = VisibilityIcon;
export const GeDeleteIcon = DeleteIcon;
export const GeEditIcon = EditIcon;
export const GeCorrectIcon = Spellcheck;
export const GeDisableIcon = PauseIcon;
export const GeEnableIcon = PlayIcon;
export const GeRemoveIcon = RemoveIcon;
export const GeOpenExternal = OpenInNew;
export const GeEmailSent = EmailArrowRight;

export const GeSourceText = CardTextOutline;
export const GeTargetText = Transcribe;
export const GeTargetLanguageText = Translate;
export const GeSourceLanguageText = FormatTextVariant;
export const GeGroupSegmentsBy = FormatListGroup;
export const GeReferenceColumn = PoundBoxOutline;

export const GeCancel = ClearIcon;
export const GeResume = RefreshIcon;

export const GeTool = Settings;
export const GeToolGroup = SettingsSuggest;
export const GeAuditLog = VpnKey;
