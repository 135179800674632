// AuditLogController
export const auditLogEndpoint = '/api/audit-log';
export const auditLogUsersEndpoint = '/api/audit-log/users';
export const auditLogOperationsEndpoint = '/api/audit-log/operations';
export const auditLogEntitiesEndpoint = '/api/audit-log/entities';

// AuthController
export const googleAuthEndpoint = '/api/auth/google';
export const microsoftAuthEndpoint = '/api/auth/microsoft';
export const refreshTokenEndpoint = '/api/auth/refresh-token';
export const isAuthorizedEndpoint = '/api/auth/authorized';

// GeneralController
export const appliesToEndpoint = '/api/general/applies-to';
export const standardColumnsEndpoint = '/api/general/standard-columns';
export const writingDirectionsEndpoint = '/api/general/writing-directions';
export const writingSystemsEndpoint = '/api/general/writing-systems';

// LanguagesController
export const languagesEndpoint = '/api/languages';
export const languagesExceptEndpoint = '/api/languages/except';
export const newEquivalencyLanguagesEndpoint = '/api/languages/new-equivalency-languages';
export const languageEntryTypesEndpoint = '/api/languages/language-entry-types';
export const catToolsEndpoint = '/api/languages/cat-tools';
export const languageOriginEndpoint = '/api/languages/cat-tools-language-origin';
export const mappingsPreflightEndpoint = '/api/languages/pre-flight';
export const mappingsUploadEndpoint = '/api/languages/upload';
export const equivalenciesEndpoint = '/api/languages/equivalencies';

// MeController
export const userProfileEndpoint = '/api/me';
export const passwordResetValidateEndpoint = '/api/me/password-reset/validate';
export const passwordResetEndpoint = '/api/me/password-reset';

// RolesController
export const rolesEndpoint = '/api/roles';
export const rolesDropdownEndpoint = '/api/roles/dropdown';

// ToolGroupsController
export const toolGroupsEndpoint = '/api/tool-groups';
export const toolGroupsDropdownEndpoint = '/api/tool-groups/dropdown';

// ToolsController
export const toolsEndpoint = '/api/tools';
export const toolsDropdownEndpoint = '/api/tools/dropdown';

// UploaderController
export const uploadsEndpoint = '/api/uploader';

// UsersController
export const usersEndpoint = '/api/users';
export const serviceAccountCreateEndpoint = '/api/users/service-account';

// XtrfController
export const xtrfClientsEndpoint = '/api/xtrf/clients'
export const xtrfLanguagesEndpoint = '/api/xtrf/languages'