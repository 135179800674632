import {Avatar} from "@mui/material";
import React from "react";
import clsx from "clsx";
import {generateColorFromString} from "../../Services/constantsAndTools";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  mini: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: 8,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
  },
  normal: {
    fontSize: 16,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: 22,
  },
}));

const CustomAvatar = ({ picture, text, size = "small", className = null }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {picture !== null ? (
        <Avatar
          className={clsx(classes[size], className)}
          alt={text}
          src={picture}
        />
      ) : (
        <Avatar
          className={clsx(classes[size], className)}
          alt={text}
          style={{
            backgroundColor: generateColorFromString(text),
          }}
        >
          {text
            .split(" ")
            .map((x) => x[0])
            .slice(0, 2)
            .join("")}
        </Avatar>
      )}
    </React.Fragment>
  );
};

export default CustomAvatar;
