import {CircularProgress} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import React, {useState} from "react";

import Grid from "@mui/material/Grid";
import MuiAlert from '@mui/material/Alert';
import Snackbar from "@mui/material/Snackbar";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertProgress = withStyles((theme) => ({
    root: {
        marginRight: 10,
    },
    colorPrimary: {
        color:
            theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.primary,
    },
}))(CircularProgress);

export default function Notification(props) {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const types = ["normal", "success", "info", "warning", "error"];
    const [type, setType] = useState(types[0]);
    const [autoHideDuration, setAutoHideDuration] = useState();

    const setup = () => {
        if (props.value.hasOwnProperty("close") && props.value.close === true) {
            setOpen(false);
            return undefined;
        }

        if (
            props.value.hasOwnProperty("type") &&
            types.includes(props.value.type.toLowerCase())
        ) {
            setType(props.value.type.toLowerCase());
        }

        setMessage(props.value.message);
        if (props.value.hasOwnProperty("loading") && props.value.loading === true) {
            setLoading(true);
        } else {
            setLoading(false);
            setAutoHideDuration(3000);
        }
        setOpen(true);
    };

    React.useEffect(() => setup(), []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return type === "normal" ? (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            ContentProps={{
                "aria-describedby": "message-id",
            }}
            message={
                <div>
                    <Grid
                        container
                        id="message-id"
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Grid item>
                            {loading ? <AlertProgress thickness={6} size={20}/> : null}
                        </Grid>
                        <Grid item>{message}</Grid>
                    </Grid>
                </div>
            }
        />
    ) : (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            ContentProps={{
                "aria-describedby": "message-id",
            }}
        >
            <div>
                <Grid
                    container
                    id="message-id"
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Grid item>
                        <Alert severity={type}>{message}</Alert>
                    </Grid>
                </Grid>
            </div>
        </Snackbar>
    );
}
