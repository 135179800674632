import { Typography } from "@mui/material";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { GeDeleteIcon, GeEditIcon } from "../General/GeneralIcons";

const Entry = ({
  entry,
  allowActions = true,
  onEdit,
  onDelete,
}) => {

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };
  const handleClickDelete = (event) => {
    onDelete(event, entry);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} sm={10} md={allowActions ? 3 : 4}>
          <Typography variant="body2" display="inline">
            {entry.group.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={allowActions ? 3 : 4}>
          <Typography variant="body2" display="inline">
            {entry.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={4}>
          <Typography variant="body2" display="inline">
            {entry.description}
          </Typography>
        </Grid>
        {allowActions && (
          <Grid item xs={12} sm={2} md={2} style={{ textAlign: "center" }}>
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="Edit Tool"
                component="span"
                onClick={handleClickEdit}
                title="Edit Tool"
              >
                <GeEditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="Delete Tool"
                component="span"
                onClick={handleClickDelete}
                title="Delete Tool"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            </React.Fragment>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Entry;
