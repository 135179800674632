import {CircularProgress, Grow} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import ErrorIcon from "@mui/icons-material/Error";
import Grid from "@mui/material/Grid";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

const useStyles = makeStyles((theme) => ({
    headerIcon: {
        fontSize: 16,
        verticalAlign: "text-bottom",
    },
    errorNormal: {
        borderRadius: 5,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 8,
        paddingRight: 8,
    },
    errorOutlined: {
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: "solid",
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 7,
        paddingRight: 7,
    },
    compact: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 7,
        paddingRight: 7,
    },
    noBorder: {
        border: "none",
        padding: 0,
    },
    expand: {flex: 1},
    normal: {
        backgroundColor: "inherit",
        color: "inherit",
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    normalOutline: {
        borderColor: "inherit",
    },
    successOutline: {
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.success.dark,
    },
    infoOutline: {
        borderColor: theme.palette.info.main,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.info.dark,
    },
    warningOutline: {
        borderColor: theme.palette.warning.main,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.warning.dark,
    },
    errorOutline: {
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.error.dark,
    },
}));

const MyTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 400,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

export default function StateIndicator({
                                           message = "",
                                           tooltip = "",
                                           tooltipPosition = "bottom",
                                           noIcon = false,
                                           icon = null,
                                           loading = false,
                                           determinate = false,
                                           progress = 0,
                                           total = 100,
                                           outline = false,
                                           noBorder = false,
                                           compact = false,
                                           type = "error",
                                           scale = 100,
                                       }) {
    const classes = useStyles();
    const Icon = icon;

    const classType = outline
        ? classes[type.toLocaleLowerCase() + "Outline"]
        : classes[type.toLocaleLowerCase()];

    const classBorder = noBorder ? classes.noBorder : null;
    const classCompact = compact ? classes.compact : null;

    const formattedTooltip = tooltip
        ? tooltip.split("\n").map((item, key) => {
            return (
                <span key={key}>
            {item}
                    <br/>
          </span>
            );
        })
        : "";
    return (
        <MyTooltip title={formattedTooltip} placement={tooltipPosition}>
      <span
          style={{
              display: "flex",
              transform: `scale(${scale}%)`,
              transformOrigin: "left",
          }}
      >
        <Grow in={true}>
          <div
              className={
                  outline
                      ? clsx(
                          classes.errorOutlined,
                          classType,
                          classBorder,
                          classCompact
                      )
                      : clsx(
                          classes.errorNormal,
                          classType,
                          classBorder,
                          classCompact
                      )
              }
          >
            <Grid container spacing={1} alignItems="center" >
              {loading && (
                  <Grid item>
                      <CircularProgress size={15} color="inherit" style={{verticalAlign:"middle"}}/>
                  </Grid>
              )}
                {!noIcon && !loading && (
                    <Grid item>
                        {Icon !== null ? (
                            <Icon className={classes.headerIcon}/>
                        ) : (
                            <ErrorIcon className={classes.headerIcon}/>
                        )}
                    </Grid>
                )}
                {message && (
                    <Grid item className={classes.expand}>
                        {message}
                    </Grid>
                )}
                {loading && determinate && (
                    <Grid item sm={12} style={{textAlign:"right"}}>
                        <LinearProgressWithLabel variant={"determinate"} color="inherit" value={Math.floor(progress * 100 / total)} label={`${progress}/${total}`} />
                    </Grid>
                )}
            </Grid>
          </div>
        </Grow>
      </span>
        </MyTooltip>
    );
}
