import {Avatar} from "@mui/material";
import React from "react";
import {generateColorFromString} from "../../Services/constantsAndTools";
import {styled} from "@mui/material/styles";


const sxStyles = {
  mini: {
    width: 2,
    height: 2,
    fontSize: 8
  },
  small: {
    width: 3,
    height: 3,
    fontSize: 12
  },
  normal: {
    fontSize: 16
  },
  large: {
    width: 7,
    height: 7,
    fontSize: 22
  },
}

const StyledAvatar = styled(Avatar)(({ size = "small",overrides = null, theme }) => {
  const sizeProperties = {
    width: theme.spacing(sxStyles[size].width),
    height: theme.spacing(sxStyles[size].height),
    fontSize: sxStyles[size].fontSize,
  }
  return {
   ...sizeProperties,...overrides
  };
});

const CustomAvatar = ({ picture, text, size = "small", overrides = null }) => {
  return (
    <React.Fragment>
      {picture !== null ? (
        <StyledAvatar
          size={size}
          overrides={overrides}
          alt={text}
          src={picture}
        />
      ) : (
          <StyledAvatar
              size={size}
              overrides={overrides}
          alt={text}
          sx={{
            backgroundColor: generateColorFromString(text),
          }}
        >
          {text
            .split(" ")
            .map((x) => x[0])
            .slice(0, 2)
            .join("")}
        </StyledAvatar>
      )}
    </React.Fragment>
  );
};

export default CustomAvatar;
