import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import history from "./history";
import qs from "qs";
import { userStore } from "./store";
import { refreshTokenEndpoint } from "./apiEndpoint";

const api = axios.create({
  timeout: 30000,
  headers: { "Content-Type": "application/json" },
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

function getAccessToken() {
  return userStore.token;
}

function getRefreshToken() {
  return userStore.refresh_token;
}

api.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;

  request.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "indices",
      encode: false,
    });
  };

  return request;
});

//v2 skipWhileRefreshing  -> v3 pauseInstanceWhileRefreshing
const refreshAuthLogic = (failedRequest) => {
  axios
    .post(process.env.REACT_APP_BACKEND_URL + refreshTokenEndpoint, {
      Token: getAccessToken(),
      RefreshToken: getRefreshToken(),
      pauseInstanceWhileRefreshing: true, //this parameter is for the internal refresh logic inside this react dependency
    })
    .then((tokenRefreshResponse) => {
      userStore.token = tokenRefreshResponse.data.accessToken;
      userStore.refresh_token = tokenRefreshResponse.data.refreshToken;
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.accessToken;
      return Promise.resolve();
    })
    .catch((error) => {
      //this requires history v 4.10.1
      history.push("/logout");
      return Promise.reject();
    });
}

createAuthRefreshInterceptor(api, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
});

export default api;
