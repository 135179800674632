import { Grid, IconButton } from "@mui/material";
import React from "react";
import { TpRemoveIcon } from "../../General/GeneralIcons";

export const EquivalencyMappingEntry = ({ index, mapping, handleRemove }) => {

  const removeMapping = () => {
    handleRemove(index);
  };

  return (
    <React.Fragment>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            {mapping.tool.text}
          </Grid>
          <Grid item sm={3}>
            {mapping.standardColumn.text}
          </Grid>
          <Grid item sm={4}>
            {mapping.matchingColumn.text}
          </Grid>
          <Grid item sm={1}>
            <IconButton
              size={"small"}
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={removeMapping}
              title="Remove"
            >
              <TpRemoveIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}