import {
    Collapse,
    Fade,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from "@mui/material";
import React, {useMemo, useState} from "react";

import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import PolicyIcon from "@mui/icons-material/Policy";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { green,lightGreen,orange ,red,yellow} from '@mui/material/colors';
import zxcvbn from "zxcvbn";

const useStyles = makeStyles((theme) => ({
    passwordStatus: {marginTop: 5},
    strengthDiv: {
        display: "flex",
        alignItems: "center",
    },
    strengthText: {
        lineHeight: "normal",
        marginRight: 5,
        marginLeft: 10,
    },
    strengthDot: {
        height: 12,
        width: 12,
        backgroundColor: "#bbb",
        borderRadius: "50%",
        display: "inline-block",
        marginRight: 5,
    },
    iconYes: {
        fontSize: 14,
        color: green[500],
        marginRight: 5,
    },
    iconNot: {
        fontSize: 14,
        color: red[500],
        marginRight: 5,
    },
    iconActivated: {
        color: theme.palette.secondary.main,
    },
    policyHeader: {
        marginTop: 10,
    },
    policySubHeader: {
        marginTop: 5,
    },
    policy: {
        marginLeft: 10,
    },
    pWeak: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    pOk: {
        color: theme.palette.getContrastText(yellow[500]),

        backgroundColor: yellow[500],
    },
    pGood: {
        color: theme.palette.getContrastText(orange[500]),

        backgroundColor: orange[500],
    },
    pStrong: {
        color: theme.palette.getContrastText(lightGreen[500]),

        backgroundColor: lightGreen[500],
    },
    pStronger: {
        color: theme.palette.getContrastText(green[500]),

        backgroundColor: green[500],
    },
}));

const defaultPolicies = {
    minStrengthRequired: 2,
    minOptionalChecksRequired: 3,
    checks: [
        {
            description: "Minimum 8 characters",
            regex: /./g,
            minimumRequired: 8,
            type: "required",
        },
        {
            description: "Uppercase letters",
            regex: /[A-Z]/g,
            minimumRequired: 1,
            type: "optional",
        },
        {
            description: "Lowercase letters",
            regex: /[a-z]/g,
            minimumRequired: 1,
            type: "optional",
        },
        {
            description: "Digits",
            regex: /[0-9]/g,
            minimumRequired: 1,
            type: "optional",
        },
        {
            description: "Special characters",
            regex: /\W|_/g,
            minimumRequired: 1,
            type: "optional",
        },
    ],
};

export default function PasswordField({
                                          value,
                                          setValue,
                                          label = "Password",
                                          setValid = null,
                                          showError = false,
                                          disabled = false,
                                          policies = defaultPolicies,
                                          showPasswordPolicy = false,
                                      }) {
    const classes = useStyles();

    const [passwordScore, setPasswordScore] = useState(0);
    const [policyCompliant, setPolicyCompliant] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [openPasswordPolicy, setOpenPasswordPolicy] = useState(
        showPasswordPolicy
    );

    const [policiesChecks, setPoliciesChecks] = useState(
        policies.checks.map((x) => {
            return {...x, valid: false};
        })
    );
    const requiredPolicies = useMemo(() => policiesChecks.filter((policy) => policy.type === "required") || [], [policiesChecks]);
    const optionalPolicies = useMemo(() => policiesChecks.filter((policy) => policy.type === "optional") || [], [policiesChecks]);

    const passwordStrength = [
        {text: "weak", className: classes.pWeak},
        {text: "okay", className: classes.pOk},
        {text: "good", className: classes.pGood},
        {text: "strong", className: classes.pStrong},
        {text: "stronger", className: classes.pStronger},
    ];

    React.useEffect(() => {
        const score = zxcvbn(value).score;
        setPasswordScore(score);
        setPoliciesChecks((prevPoliciesChecks) =>
            prevPoliciesChecks.map((policy) => {
                const match = value.match(policy.regex);
                return {
                    ...policy,
                    valid: match !== null && match.length >= policy.minimumRequired,
                };
            })
        );
    }, [value]);

    React.useEffect(() => {
        const requiredChecks = !requiredPolicies.some((x) => x.valid === false);
        const optionalChecks =
            optionalPolicies.filter((x) => x.valid === true).length >=
            policies.minOptionalChecksRequired;
        const valid = requiredChecks && optionalChecks;
        setPolicyCompliant(valid);
    }, [
        optionalPolicies,
        policies.minOptionalChecksRequired,
        policiesChecks,
        requiredPolicies,
    ]);

    React.useEffect(() => {
        const strengthChecks = passwordScore >= policies.minStrengthRequired;
        const valid = policiesChecks && strengthChecks;
        setIsValid(valid);
        if (setValid !== null) {
            setValid(valid);
        }
    }, [
        passwordScore,
        policies.minStrengthRequired,
        policiesChecks,
        policyCompliant,
        setValid,
    ]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const onPasswordChange = (event) => {
        setValue(event.target.value);
    };

    const StrengthIndicator = ({strength}) => (
        <div className={classes.strengthDiv}>
            <div className={clsx(classes.strengthDot, strength.className)}/>
            <Typography variant={"caption"}>{strength.text}</Typography>
        </div>
    );

    const Policy = ({policy}) => (
        <Grid item className={classes.policy}>
            <Grid container alignItems={"center"}>
                {policy.valid ? (
                    <CheckIcon className={classes.iconYes}/>
                ) : (
                    <BlockIcon className={classes.iconNot}/>
                )}
                <Typography display={"inline"}>{policy.description}</Typography>
            </Grid>
        </Grid>
    );
    const PasswordValidity = ({value}) => (
        <Grid item>
            <Grid container alignItems={"center"}>
                {value ? (
                    <CheckIcon className={classes.iconYes}/>
                ) : (
                    <BlockIcon className={classes.iconNot}/>
                )}
                <Typography variant={"caption"} display={"inline"}>
                    {value ? "The password is valid" : "The password is invalid"}
                </Typography>
            </Grid>
        </Grid>
    );

    const PasswordPolicies = ({policies}) => (
        <Grid container direction={"column"}>
            <Typography className={classes.policyHeader}>
                Strength Requirement
            </Typography>
            <Grid item className={classes.policy}>
                <Grid container alignItems={"center"}>
                    <StrengthIndicator
                        strength={passwordStrength[policies.minStrengthRequired]}
                    />
                </Grid>
            </Grid>
            <Typography className={classes.policyHeader}>Password Policy</Typography>
            <Grid item className={classes.policySubHeader}>
                Required properties
            </Grid>
            {requiredPolicies.map((policy, index) => (
                <Policy policy={policy} key={index}/>
            ))}
            <Grid item className={classes.policySubHeader}>
                At least {policies.minOptionalChecksRequired} should be valid
            </Grid>
            {optionalPolicies.map((policy, index) => (
                <Policy policy={policy} key={index}/>
            ))}
        </Grid>
    );

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Input
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                disabled={disabled}
                value={value}
                onChange={onPasswordChange}
                error={value !== "" && showError && !isValid}
                endAdornment={
                    <InputAdornment position="end">
                        <Fade in={value !== ""}>
                            <IconButton
                                tabIndex={-1}
                                size="small"
                                aria-label="toggle password visibility"
                                title={showPassword ? "hide password" : "show password"}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? (
                                    <Visibility className={classes.iconActivated}/>
                                ) : (
                                    <VisibilityOff/>
                                )}
                            </IconButton>
                        </Fade>
                        <IconButton
                            tabIndex={-1}
                            size="small"
                            aria-label="toggle password policy"
                            title={
                                openPasswordPolicy
                                    ? "hide password policy"
                                    : "show password policy"
                            }
                            onClick={() => setOpenPasswordPolicy(!openPasswordPolicy)}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {openPasswordPolicy ? (
                                <PolicyIcon className={classes.iconActivated}/>
                            ) : (
                                <PolicyIcon/>
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Grid
                container
                justifyContent="space-between"
                className={classes.passwordStatus}
            >
                <Grid item>
                    <PasswordValidity value={isValid}/>
                    <Collapse in={openPasswordPolicy}>
                        <PasswordPolicies policies={policies}/>
                    </Collapse>
                </Grid>

                <Grid item>
                    <Grid container alignItems={"center"}>
                        <Typography variant={"overline"} className={classes.strengthText}>
                            Strength
                        </Typography>
                        <StrengthIndicator strength={passwordStrength[passwordScore]}/>
                        <Typography variant={"overline"} className={classes.strengthText}>
                            Policy
                        </Typography>
                        {policyCompliant ? (
                            <CheckIcon className={classes.iconYes} title="Compliant"/>
                        ) : (
                            <BlockIcon className={classes.iconNot} title="Not compliant"/>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </FormControl>
    );
}
