import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import ConfirmDialog from "../../General/ConfirmDialog";
import Create from "./Create";
import Edit from "./Edit";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../Home";
import MyTable from "../../General/Pagination/MyTable";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";
import { isAuthorizedEndpoint, rolesDropdownEndpoint, usersEndpoint } from "../../../Services/apiEndpoint";
import UserCredential from "./UserCredential";

const statusValues = [
  { id: "true", text: "Active" },
  { id: "false", text: "Inactive" },
];

const newUser = {
  email: undefined,
  password: undefined
};

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [resetParams, setResetParams] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const [createdUser, setCreatedUser] = React.useState(newUser);
  const [openCredential, setOpenCredential] = React.useState(false);

  const isAdmin = homeState.userProfile.role === "Admin";

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const tableOptions = {
    columns: [
      {
        name: "FullName",
        description: "Name",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 5,
      },
      {
        name: "Email",
        description: "Email",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: 4,
      },
      {
        name: "RoleId",
        description: "Role",
        sortable: true,
        filter: {
          enabled: false,
        },
        size: isAdmin ? 2 : 3,
      },
    ],
    defaultSort: [{ name: "FullName", order: "" }],
    additionalFilters: [
      {
        name: "FullName",
        description: "Name",
        filter: {
          enabled: true,
          name: "NameOrEmailLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter name/email",
        },
        size: isAdmin ? 4 : "auto",
      },
      {
        name: "RoleId",
        description: "Role",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: rolesDropdownEndpoint,
            multiple: false,
            liveOptions: false,
            checkbox: false,
          },
        },
        size: 2,
      },
      {
        name: "Active",
        description: "Active Status",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "select",
          value: statusValues,
          defaultValue: "true",
        },
        size: 2,
      },
    ],
  };

  React.useEffect(() => {
    api
      .get(isAuthorizedEndpoint)
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Users",
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickConfirmSendReset = (event, entry) => {
    setResetParams({ id: entry.id });
    setOpenReset(true);
  };
  const cancelSendReset = () => {
    setResetParams(null);
  };

  const sendResetEmail = (params) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Sending password reset email", loading: true },
    });
    api
      .post(`/api/users/${params.id}/passwordReset`)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: response.data.message, type: response.data.status },
        });
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const onCreate = (newUser) => {
    refreshItems();
    setCreatedUser(newUser);
    setOpenCredential(true);
  }

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        {isAdmin && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New Service User
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MyTable
            options={tableOptions}
            url={usersEndpoint}
            refresh={refresh}
            label={"Users"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                isAdmin={isAdmin}
                onEdit={handleClickEdit}
                onSendReset={handleClickConfirmSendReset}
              />
            )}
          />
        </Grid>
      </Grid>
      {isAdmin && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={onCreate}
          />
          <UserCredential
            open={openCredential}
            setOpen={setOpenCredential}
            createdUser={createdUser}
          />
          <ConfirmDialog
            open={openReset}
            setOpen={setOpenReset}
            title={"Send reset email"}
            message={
              "Are you sure that you want to send the user an email with instructions to reset their own password?"
            }
            onCloseSuccess={sendResetEmail}
            successParams={resetParams}
            onClose={cancelSendReset}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
