import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useState } from "react";
import api from "../../Services/api";
import { mappingsPreflightEndpoint } from "../../Services/apiEndpoint";
import { errorManagement } from "../../Services/errorManagement";
import { HomeAppContext } from "../Home";
import UploadForm from "./Uploader/UploadForm";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "90vh!important",
    maxHeight: "90vh!important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  tool: {},
  appliesTo: { id: 1 },
  xtrfClient: "",
  tmsClient: "",
  salesforceClient: "",
  files: [],
  fileReaderConfiguration: {
    identifierColumn: {},
    nameColumn: {},
    shortNameColumn: {},
    codeColumn: {},
    iso639Dash1Column: {},
    iso639Dash2Column: {},
    externalIdColumn: {},
    writingDirectionColumn: {},
    writingSystemColumn: {}
  },
  equivalencyConfiguration: {
    caseSensitive: false,
    hyphenIsUnderscore: false,
    mappings: []
  }
};

export default function Upload({ open, setOpen, openSummary, setResponse, setUploadParams, setNotification }) {
  const classes = useStyles();

  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(initialEntry);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  React.useEffect(() => {
    setSubmitted(false);
    setErrors([]);
    setInternalEntry(initialEntry);
    setLoading(false);
  }, [open]);

  const getEntry = (addNonEquivalent) => {
    return {
      ...internalEntry,
      tool: internalEntry.tool || null,
      xtrfClient: internalEntry.xtrfClient || null,
      tmsClient: internalEntry.tmsClient || null,
      salesforceClient: internalEntry.salesforceClient || null,
      files: (internalEntry.files || []).filter(x => { return !x.error }),
      addNonEquivalent: addNonEquivalent
    };
  }
  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };
  const handleDryRun = () => {
    setSubmitted(true);
    setLoading(true);
    const entryDto = getEntry(false);

    setResponse(undefined);
    setNotification(undefined);
    openSummary(true);

    homeState.connection.on(
      "ReceiveFileImportProgressMessage",
      (notification) => {
        setNotification(notification);
      }
    );

    api.post(mappingsPreflightEndpoint, entryDto, { timeout: 0 })
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "File(s) Processed", type: "success" },
        });
        setResponse(response.data);
        // openSummary(true);
        setLoading(false);
        setUploadParams(entryDto);
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <React.Fragment>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-create-entry"
      >
        <DialogTitle id="alert-dialog-create-entry">{"Upload Mappings"}</DialogTitle>
        <DialogContent>
          <UploadForm
            entry={internalEntry}
            errors={errors}
            setEntry={setInternalEntry}
            loading={loading}
            submitted={submitted}
            setSaveDisabled={setSaveDisabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleDryRun}
            color="primary"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Validate Upload
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}