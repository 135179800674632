import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {GeCopyIcon} from "./GeneralIcons";
import React, {useContext} from "react";
import {HomeAppContext} from "../Home";


const CopyValue = ({ value }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(value);
    homeDispatch({
      type: "NOTIFICATION",
      data: {
        message: "Value copied to the clipboard",
        type: "info",
      },
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item>{value}</Grid>
      <Grid item>
        <IconButton
          size={"small"}
          color="secondary"
          aria-label="url-copy"
          title="Copy value to clipboard"
          onClick={onCopyToClipboard}
        >
          <GeCopyIcon sx={{ fontSize: 14}}/>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CopyValue;
