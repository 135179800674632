import Grid from "@mui/material/Grid";
import React from "react";

const RenderUser = ({ entry }) => {
  return (
    <React.Fragment>
      <Grid item sm={12}></Grid>
    </React.Fragment>
  );
};

const UserPreferenceDetails = ({ entry }) => {
  return {
    Admin: <RenderUser entry={entry} />,
    PowerUser: <RenderUser entry={entry} />,
    User: <RenderUser entry={entry} />,
  }[entry.role];
};

export default UserPreferenceDetails;
