import { Box, Button, CircularProgress, Divider, Grid, TextField } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import React, { useState } from "react";
import clsx from "clsx";
import NoEntriesFound from "../../General/Pagination/NoEntriesFound";
import { makeStyles } from "@mui/styles";
import { optionsFilter } from "../../../Services/constantsAndTools";
import AddEquivalenciesDialog from "./AddEquivalenciesDialog";
import { appliesToEndpoint, newEquivalencyLanguagesEndpoint, toolsDropdownEndpoint } from "../../../Services/apiEndpoint";
import api from "../../../Services/api";
import CustomAutocomplete from "../../General/CustomAutocomplete";
import MappedEquivalencyEntry from "./MappedEquivalencyEntry";

const useStyles = makeStyles((theme) => ({
  entryZone: {
    transition: "0.5s filter linear",
  },
  loadingEffect: {
    filter: "opacity(0.5)",
  },
  header: {
    fontWeight: "bold",
  },
}));

const initialState = {
  filteredEntries: [],
  loading: true,
  filters: {
    tools: [],
    appliesTo: [],
    language: "",
    code: ""
  },
};

export default function Equivalencies({
  entry = null,
  equivalencies,
  loading,
  errors = [],
  onAllCheckboxChange,
  setEquivalencies,
  submitted,
  setSaveDisabled,
}) {
  const classes = useStyles();

  const [state, setState] = useState(initialState);
  const [openLanguageSelector, setOpenLanguageSelector] = useState(false);

  const languageStringifyOption = (option) => `${option.name}`;
  const codeStringifyOption = (option) => `${option.code}`;
  React.useEffect(() => {
    const byTool = state.filters.tools.length === 0
      ? equivalencies
      : equivalencies.filter(x => { return state.filters.tools.map(t => t.id).includes(x.tool.id) });

    const byAppliesTo = state.filters.appliesTo.length === 0
      ? byTool
      : byTool.filter(x => { return state.filters.appliesTo.map(t => t.id).includes(x.appliesTo.id) });

    const byLanguage =
      state.filters.language === ""
        ? byAppliesTo
        : optionsFilter(byAppliesTo, state.filters.language, languageStringifyOption);

    const byCode =
      state.filters.code === ""
        ? byLanguage
        : optionsFilter(byLanguage, state.filters.code, codeStringifyOption);

    setState((prevState) => ({
      ...prevState,
      loading: false,
      filteredEntries: byCode,
    }));
  }, [equivalencies, state.filters]);

  const handleClickAdd = (event, entry) => {
    setOpenLanguageSelector(true);
  };
  const setFilter = (value, filterName) =>
    setState((prevState) => ({
      ...prevState,
      filters: { ...prevState.filters, [filterName]: value },
    }));

  const addEquivalencies = (ids) => {
    async function getItems(ids) {
      const params = {
        ids: ids,
      };
      try {
        const response = await api.get(newEquivalencyLanguagesEndpoint, {
          params: params,
        });
        setEquivalencies([...equivalencies, ...response.data]);
      } catch (error) { }
    }
    const resourcesLanguages = equivalencies.map((x) => x.id);
    const newLanguages = ids.filter((x) => !resourcesLanguages.includes(x));
    if (newLanguages.length !== 0) {
      getItems(newLanguages);
    }
  };

  const removeEquivalencies = (resource) => {
    setEquivalencies(equivalencies.filter((x) => x.id !== resource.id));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center" justifyContent={"space-between"}>
        <Grid item sm={12}>
          <Grid container justifyContent={"flex-end"}>
            <Grid item>
              <Button
                onClick={handleClickAdd}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddBox />}
              >
                {equivalencies.length === 0 ? "Define Equivalencies" : "Add Equivalencies"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <CustomAutocomplete
                value={state.filters.tools}
                setValue={(value) => setFilter(value, "tools")}
                related={null}
                onLoading={setSaveDisabled}
                url={toolsDropdownEndpoint}
                multiple={true}
                checkbox={true}
                label={"Tool"}
                placeholder={"Select a tool"}
                noOptionsText={"No tool found"}
                loadingText={"Searching"}
                margin={"none"}
                liveOptions={false}
              />
            </Grid>
            <Grid item sm={4}>
              <CustomAutocomplete
                value={state.filters.appliesTo}
                setValue={(value) => setFilter(value, "appliesTo")}
                related={null}
                onLoading={setSaveDisabled}
                url={appliesToEndpoint}
                multiple={true}
                checkbox={true}
                label={"Applies to"}
                placeholder={"Select a target"}
                noOptionsText={"No target found"}
                loadingText={"Searching"}
                margin={"none"}
                liveOptions={false}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                fullWidth
                label="Language"
                value={state.filters.language}
                onChange={(event) =>
                  setFilter(event.target.value, "language")
                }
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                fullWidth
                label="Code"
                value={state.filters.code}
                onChange={(event) =>
                  setFilter(event.target.value, "code")
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} ></Grid>
        <Grid item sm={12}
          style={{
            display:
              state.loading && state.filteredEntries.length === 0
                ? "block"
                : "none",
          }}
        >
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}
            className={
              !state.loading
                ? classes.entryZone
                : clsx(classes.entryZone, classes.loadingEffect)
            }
          >
            {loading ? (
              <Grid container justifyContent={"center"}>
                <CircularProgress />
              </Grid>
            ) : (
              state.filteredEntries.length > 0 &&
              <React.Fragment>
                <Grid item sm={12}>
                  <Grid container spacing={2}>
                    <Grid item sm={2}>
                      <Box component={"div"} className={classes.header}>{"Tool"}</Box>
                    </Grid>
                    <Grid item sm={2}>
                      <Box component={"div"} className={classes.header}>{"Applies To"}</Box>
                    </Grid>
                    <Grid item sm={3}>
                      <Box component={"div"} className={classes.header}>{"Language"}</Box>
                    </Grid>
                    <Grid item sm={3}>
                      <Box component={"div"} className={classes.header}>{"Identifier"}</Box>
                    </Grid>
                    <Grid item sm={1}>
                      <Box component={"div"} className={classes.header}>{"Code"}</Box>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </Grid>
                {state.filteredEntries.map((equivalency, index) => (
                  <MappedEquivalencyEntry
                    key={index}
                    entry={entry}
                    equivalency={equivalency}
                    handleRemove={removeEquivalencies}
                  />
                ))}
              </React.Fragment>
            )}
            {!state.loading &&
              state.filteredEntries.length === 0 &&
              (<NoEntriesFound message={"No Equivalencies found"} />)}
          </Grid>
        </Grid>
      </Grid>
      <AddEquivalenciesDialog
        entry={entry}
        open={openLanguageSelector}
        setOpen={setOpenLanguageSelector}
        onAddEquivalencies={addEquivalencies}
        equivalencies={equivalencies}
      />
    </React.Fragment>
  )
}