import { Route, Switch } from "react-router-dom";

import LanguageList from "./Language/List";
import Login from "./Login";
import Logout from "./Logout";
import React from "react";
import RedirectPage from "./RedirectPage";
import UserList from "./Admin/Users/List";
import UserResetPassword from "./User/UserResetPassword";
import ToolList from './Tool/List';
import ToolGroupList from './ToolGroup/List';
import AuditLogList from './AuditLog/List';

const RenderAdmin = () => (
  <Switch>
    <Route path="/redirect" component={RedirectPage} />
    <Route path="/login" component={Login} />
    <Route path="/resetPassword" component={UserResetPassword} />
    <Route path="/logout" component={Logout} />
    <Route path="/users" component={UserList} />
    <Route path="/languages" component={LanguageList} />
    <Route path="/tools" component={ToolList} />
    <Route path="/tool-groups" component={ToolGroupList} />
    <Route path="/audit-logs" component={AuditLogList} />
    <Route component={RedirectPage} />
  </Switch>
);
const RenderPowerUser = () => (
  <Switch>
    <Route path="/redirect" component={RedirectPage} />
    <Route path="/login" component={Login} />
    <Route path="/resetPassword" component={UserResetPassword} />
    <Route path="/logout" component={Logout} />
    <Route path="/users" component={UserList} />
    <Route path="/languages" component={LanguageList} />
    <Route path="/tools" component={ToolList} />
    <Route path="/tool-groups" component={ToolGroupList} />
    <Route component={RedirectPage} />
  </Switch>
);

const RenderUser = () => (
  <Switch>
    <Route path="/redirect" component={RedirectPage} />
    <Route path="/login" component={Login} />
    <Route path="/resetPassword" component={UserResetPassword} />
    <Route path="/logout" component={Logout} />
    <Route path="/languages" component={LanguageList} />
    <Route path="/tools" component={ToolList} />
    <Route path="/tool-groups" component={ToolGroupList} />
    <Route component={RedirectPage} />
  </Switch>
);

export default function RenderRouter({ userProfile }) {
  return userProfile === null ? (
    <Switch>
      <Route path="/redirect" component={RedirectPage} />
      <Route path="/login" component={Login} />
      <Route path="/resetPassword" component={UserResetPassword} />
      <Route component={RedirectPage} />
    </Switch>
  ) : (
    {
      Admin: <RenderAdmin />,
      PowerUser: <RenderPowerUser />,
      User: <RenderUser />
    }[userProfile.role]
  );
}
