import {
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useContext, useState } from "react";

import Button from "@mui/material/Button";
import { HomeAppContext } from "../Home";
import MenuItem from "@mui/material/MenuItem";
import PasswordField from "../General/PasswordField";
import UserPicture from "./UserPicture";
import UserPreferenceDetails from "./UserPreferenceDetails";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { red } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    top: 30,
    minHeight: 250,
    maxHeight: "calc(100% - 110px)",
  },
  avatar: {
    marginBottom: 5,
  },
  userInformation: {
    padding: 10,
  },
  divider: {
    marginBottom: 10,
  },
  tabError: {
    color: red[400],
    "&::after": {
      content: '"*"',
      display: "block",
    },
  },
  iconAlignBaseline: {
    marginRight: 5,
    verticalAlign: "sub",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
  currentReportMonth: 1,
  closeDate: new Date(),
  timezone: "UTC",
  tabIndex: 0,
  tabTransitionEnded: true,
  localProfile: null,
  changePassword: false,
  currentPassword: "",
  newPassword: "",
  newPasswordRepeat: "",
};

export default function UserPreferences({
  open,
  setOpen,
  defaultTabIndex = 0,
}) {
  const classes = useStyles();
  const { homeDispatch } = useContext(HomeAppContext);
  const [validNewPassword, setValidNewPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editingPicture, setEditingPicture] = useState(false);

  const [state, setState] = useState({
    ...initialState,
    tabIndex: 0,
  });

  const isInternalUser =
    state === null
      ? false
      : state.localProfile?.authType?.name === "Internal" ?? false;

  const validPassword =
    validNewPassword &&
    state.newPasswordRepeat !== "" &&
    state.newPassword === state.newPasswordRepeat;

  const showPasswordError =
    (state.newPassword !== "" || state.newPasswordRepeat !== "") &&
    validNewPassword &&
    state.newPassword !== state.newPasswordRepeat;

  const getEntry = useCallback(() => {
    setEditingPicture(false);
    setLoading(true);
    api
      .get(`/api/me/preferences`)
      .then((r) => {
        setState((prevState) => ({
          ...prevState,
          currentReportMonth: r.data.currentReportMonth,
          closeDate: r.data.closeDate,
          timezone: r.data.timezone,
          localProfile: r.data.user,
        }));
        setLoading(false);
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  }, [homeDispatch]);

  React.useEffect(() => {
    if (open) {
      getEntry();
    }
  }, [getEntry, open]);

  const handleCloseSaveDialog = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating profile", loading: true },
    });
    setLoading(true);

    const changes = {
      theme: state.localProfile.theme,
      currentPassword: state.currentPassword,
      newPassword: state.newPassword,
      picture: state.localProfile.pictureChanged
        ? state.localProfile.picture
        : null,
      notificationConfig: state.localProfile.notificationConfig,
      calendar: state.localProfile.calendar,
    };
    api
      .put(`/api/me/profile/`, changes)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Profile updated", type: "success" },
        });
        setOpen(false);
        setLoading(false);
        homeDispatch({
          type: "UPDATE_USER_PROFILE_VALUES",
          data: response.data,
        });
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const handleCloseDialog = () => {
    if (!loading) {
      setOpen(false);
      setLoading(false);
    }
  };

  const handleChangeTheme = (event) => {
    setState((prevState) => ({
      ...prevState,
      localProfile: { ...prevState.localProfile, theme: event.target.value },
    }));
  };

  const handleChangeValue = (value, property) => {
    setState((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  const handleChangePicture = (newPicture) => {
    setState((prevState) => ({
      ...prevState,
      localProfile: {
        ...prevState.localProfile,
        picture: newPicture,
        pictureChanged: true,
      },
    }));
  };

  const onChangePasswordChange = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      setState((prevState) => ({
        ...prevState,
        changePassword: checked,
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        changePassword: checked,
      }));
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      aria-labelledby="user-preferences-dialog"
    >
      <DialogTitle id="user-preferences-dialog">{"Preferences"}</DialogTitle>
      <DialogContent>
        {state.localProfile !== null ? (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <UserPicture
                picture={state.localProfile.picture}
                onAccept={handleChangePicture}
                onEditingPicture={setEditingPicture}
                disabled={!isInternalUser}
              />
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item sm={3}>
                  <Typography>Name</Typography>
                </Grid>
                <Grid item sm={9}>
                  <Typography>{state.localProfile.fullName}</Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item sm={9}>
                  <Typography>{state.localProfile.email}</Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography>Role</Typography>
                </Grid>
                <Grid item sm={9}>
                  <Typography>
                    {state.localProfile.roleDescription ??
                      state.localProfile.role}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography>Theme</Typography>
                </Grid>
                <Grid item sm={9}>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="preference-theme-label"
                      id="preference-theme-select"
                      value={state.localProfile.theme}
                      onChange={handleChangeTheme}
                    >
                      <MenuItem value={1}>Auto</MenuItem>
                      <MenuItem value={2}>Dark</MenuItem>
                      <MenuItem value={3}>Light</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <Typography>Password</Typography>
                </Grid>
                <Grid item sm={9}>
                  <Grid container>
                    <Grid item sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.changePassword}
                            onChange={onChangePasswordChange}
                            name="changePassword"
                            color="primary"
                            disabled={!isInternalUser}
                          />
                        }
                        label="Change my password"
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Collapse in={state.changePassword}>
                        <Grid container>
                          <Grid item sm={12}>
                            <TextField
                              required
                              autoComplete="current-password"
                              fullWidth
                              error={state.currentPassword === ""}
                              helperText={
                                showPasswordError
                                  ? "You need so specify your current password"
                                  : ""
                              }
                              type="password"
                              disabled={loading}
                              label="Current Password"
                              value={state.currentPassword}
                              onChange={(event) =>
                                handleChangeValue(
                                  event.target.value,
                                  "currentPassword"
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <PasswordField
                              value={state.newPassword}
                              setValue={(value) =>
                                handleChangeValue(value, "newPassword")
                              }
                              disabled={loading}
                              showError={
                                state.newPassword !== "" && !validPassword
                              }
                              label={"New Password"}
                              setValid={setValidNewPassword}
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <TextField
                              required
                              autoComplete="new-password-repeat"
                              fullWidth
                              error={
                                state.newPasswordRepeat !== "" && !validPassword
                              }
                              helperText={
                                showPasswordError
                                  ? "The passwords are not equal"
                                  : ""
                              }
                              type="password"
                              disabled={loading}
                              label="New Password Repeat"
                              value={state.newPasswordRepeat}
                              onChange={(event) =>
                                handleChangeValue(
                                  event.target.value,
                                  "newPasswordRepeat"
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Grid>
                <UserPreferenceDetails entry={state.localProfile} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent={"center"}>
            <CircularProgress size={100} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSaveDialog}
          color="primary"
          autoFocus
          disabled={
            loading ||
            editingPicture ||
            (state.changePassword &&
              (state.currentPassword === "" || !validPassword))
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
