import {CircularProgress, SvgIcon,} from "@mui/material";
import React, {useContext} from "react";
import {PublicClientApplication} from "@azure/msal-browser";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import {HomeAppContext} from "./Home";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import api from "../Services/api";
import makeStyles from "@mui/styles/makeStyles";
import {useHistory} from "react-router-dom";
import {userStore} from "../Services/store";
import {ReactComponent as MsLogo} from "../ms-symbollockup_mssymbol_19.svg";
import msalConfig, {MsalTenants} from "../Services/msalConfig";
import {microsoftAuthEndpoint, userProfileEndpoint} from "../Services/apiEndpoint";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    grid: {
        marginTop: theme.spacing(1),
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    dialog: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 300,
    },
    submit: {
        marginTop: 10,
    },
    forgotLink: {
        marginTop: 15,
        textAlign: "right",
    },
    microsoftLoginButton: {
        textTransform: "none",
        backgroundColor: "#fff",
        color: "black",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:hover": {
            backgroundColor:
                theme.palette.mode === "light" ? "rgba(66,133,244,.04)" : "#d2e3fc",
            boxShadow: "none",
            borderColor: "#d2e3fc",
            outline: "none",
        },
        marginTop: "5px",
        width: "250px",
        border: "1px solid #dadce0",
        borderRadius: "4px",
    },
}));

const MsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <MsLogo/>
        </SvgIcon>
    );
};


const LoginForm = ({
                       classes,
                       msLoginClick,
                   }) => {


    return (
        <Grid
            container
            className={classes.grid}
            justifyContent="center"
            spacing={2}
            direction={"column"}
        >
            <Grid item>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item>
                        <Button
                            className={classes.microsoftLoginButton}
                            onClick={(event) => msLoginClick(MsalTenants.Default)}
                            startIcon={<MsIcon/>}
                        >
                            Sign in with Microsoft
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default function Login() {
    const {homeDispatch} = useContext(HomeAppContext);
    const [service, setService] = React.useState("");
    const [working, setWorking] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const history = useHistory();


    React.useEffect(() => {
        homeDispatch({type: "UPDATE_CURRENT_LOCATION", data: "Log in"});
    }, [homeDispatch]);

    const getSystemToken = (authentication, url, params, redirect) => {
        setService(authentication);
        setStatus("Logging into the system");
        setWorking(true);
        api
            .post(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    userStore.token = data.accessToken;
                    userStore.refresh_token = data.refreshToken;
                    setStatus("Loading user profile");
                    api.get(userProfileEndpoint).then((response) => {
                        userStore.user_profile = response.data;
                        homeDispatch({
                            type: "UPDATE_USER_PROFILE",
                            data: userStore.user_profile,
                        });
                        history.push(redirect);
                    });
                }
            })
            .catch(function (error) {
                setWorking(false);
                if (error.response !== undefined) {
                    homeDispatch({
                        type: "NOTIFICATION",
                        data: {message: error.response.data},
                    });
                } else {
                    homeDispatch({
                        type: "NOTIFICATION",
                        data: {message: "Network error"},
                    });
                }
            });
    };


    const classes = useStyles();


    const logInAzure = async (tenant = MsalTenants.Default) => {
        setStatus("Logging with Azure credentials");
        setWorking(true);
        sessionStorage.clear();
        const config = msalConfig(tenant);
        const msalInstance = new PublicClientApplication(config);
        await msalInstance.initialize();
        const requestObj = {
            scopes: ["user.read", "User.Read.All"],
        };

        try {
            const loginResponse = await msalInstance.loginPopup(requestObj);
            const redirect = "/admin/users";
            const params = {
                accessToken: loginResponse.accessToken,
                idToken: loginResponse.idToken,
            };
            getSystemToken("Microsoft", microsoftAuthEndpoint, params, redirect);
        } catch (error) {
            setWorking(false);
            console.log(error);
            sessionStorage.clear();
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Modal
                className={classes.modal}
                aria-labelledby="loading-dialog"
                open={working}
            >
                <Grid
                    className={classes.dialog}
                    container
                    direction={"column"}
                    spacing={4}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                >
                    <Grid item>
                        <Typography>Login with {service}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{status}</Typography>
                    </Grid>
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Modal>
            <CssBaseline/>
            <Paper className={classes.paper}>
                <Grid container direction={"column"} spacing={4} alignItems={"center"}>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Log in
                        </Typography>
                    </Grid>

                    <Grid item>
                        <LoginForm
                            classes={classes}
                            msLoginClick={logInAzure}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

